import * as React from 'react'
import {FC, useState} from "react";
import AprModal from "@mit/apr-modal/apr-modal";
import './_filters.scss'
import {TableColumn} from "./select-table-types";
interface FiltersProps {
    columns: TableColumn[];
    applyFilters: (filters: any) => void;
}
const Filters: FC<FiltersProps> = ({columns, applyFilters}) => {
    const [showModal, setShowModal] = useState(false)
    function onSubmit() {
        applyFilters(filters)
        setShowModal(false)
    }

    const [filters, setFilters] = useState({})

    return <>
        <button className={'primary-outline'} disabled={showModal} onClick={() => setShowModal(true)}>Filters</button>
        {showModal && <AprModal heading={'Table Filters'} onClose={()=>setShowModal(false)} onSubmit={onSubmit}>
            <button type={'button'} className={'link-button shift-left'}  onClick={()=>setFilters({})}>Clear All</button>
            <div className={'filters-form'}>
                {columns.map((col: TableColumn, index) => {
                    return <div key={col.id}>
                        {col.title}
                        <input autoFocus={index === 0} className={'form-control'} type={'text'}
                               value={filters[col.id] ? filters[col.id] : ''}
                               onChange={(e)=>{setFilters({...filters, [col.id]: e.target.value})}} />
                    </div>
                })}
            </div>
        </AprModal>}
    </>
}

export default Filters