import BaseApi from "./base-api";
import {Person} from "../model/person.model";
export interface FetchPeopleResponseAws {
    people?: Person[];
    error?: any;
}
class PeopleSearchApi extends BaseApi {
    readonly url_people: string

    constructor() {
        super()
        this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
    }

    fetchPeople = async (query: string): Promise<FetchPeopleResponseAws> => {
        let response = await fetch(this.url_people + query, await this.requestHeaderGet())
        if (response.status === 200) {
            let people = await response.json()
            if (people) {
                let peopleResults: Person[] = people
                return {
                    people: peopleResults.filter((p) => p.affiliation !== 'student' && p.affiliation !== 'affiliate').map((p) => ({...p, search_name: `${p.display_name} (${p.kerberos_id})`}))
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }
}

const peopleSearchApi = new PeopleSearchApi()
export default peopleSearchApi
