import React, {FC, useState} from "react";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import './_effective-date.scss'

const EffectiveDate: FC = () => {
    const dispatch = useAppDispatch()
    const {appState} = useAppState()
    const [date, setDate] = useState<Date>(appState.inputEffectiveDate)
    const FORMAT = 'MM/DD/YYYY'

    return <div className={'effective-date'}>
        <h2 className={'heading'}>Effective Date</h2>

        <DayPickerInput onDayChange={(newDate) => {
            setDate(newDate)
            dispatch({type: 'input_effective_date', value: newDate})
        }}
                        dayPickerProps={{
                            modifiers: {
                                disabled: [
                                    {
                                        before: new Date()
                                    }
                                ]
                            }
                        }}
                        formatDate={MomentLocaleUtils.formatDate}
                        parseDate={MomentLocaleUtils.parseDate}
                        format={FORMAT}
                        value={`${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`}/>
    </div>
}
export default EffectiveDate