import AppInterface from "./app-interface";
import React, {createContext, ReactNode, useContext, useReducer} from "react";
import AppDispatchType from "./app-dispatch-type";
import {AppReducer} from "./app-reducer";

interface AppContextInterface {
    appState: AppInterface;
}

const AppContext = createContext<AppContextInterface | undefined>(undefined)
const AppDispatch = createContext<AppDispatchType | undefined>(undefined)

interface AppContextProviderInterface {
    children: ReactNode;
}

function AppContextProvider({children}: AppContextProviderInterface) {
    const [state, dispatch] = useReducer(AppReducer, {appState: {}})
    return <AppContext.Provider value={state}>
        <AppDispatch.Provider value={dispatch}>
            {children}
        </AppDispatch.Provider>
    </AppContext.Provider>
}

function useAppState() {
    const context = useContext(AppContext)
    if (context === undefined) {
        throw new Error('useAppState must be used within an AppContextProvider')
    }
    return context
}

function useAppDispatch() {
    const context = useContext(AppDispatch)
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within an AppContextProvider')
    }
    return context
}

export {
    AppContextProvider, useAppState, useAppDispatch
}