import BaseApi from "./base-api";
import {Person} from "../model/person.model";
import {USER_UNAUTHORIZED} from "../language";
import MomentLocaleUtils from "react-day-picker/moment";
import {Position} from "./types/position";

interface FetchInitializeResponse {
    can_submit_vacation?: boolean;
    is_dept_vaca_admin?: boolean;
    is_dept_sick_admin?: boolean;
    is_sick_manager?: boolean;
    is_vaca_manager?: boolean;
    is_super_sick_admin?: boolean;
    is_super_vaca_admin?: boolean;
    error?: any;
}

interface UpdateVacationSupervisorResponse {
    error?: boolean;
    errorText?: string;
}

interface FetchSaveSupervisorResponse {
    items?: any;
    error?: string[];
    warning?: string[];
}

interface FetchDepartmentsResponse {
    items?: any;
    error?: any;
    errorText?: string;
}

interface FetchSupervisedResponseAws {
    people?: Person[];
    error?: any;
}

interface FetchSupervisorsResponse {
    people?: Person[];
    error?: any;
}

export interface ValidateEmployeeResponse {
    result?: 'ALWAYS' | 'NEVER' | 'NOT_ALLOWED';
    error?: any;
}

interface FetchCanChangeSupervisorResponse {
    result?: boolean;
    error?: any;
}

interface FetchValidateLeaveSupervisorResponse {
    positions?: Position[];
    error?: any;
    warnings?: string[];
}

const sortName = (a, b) => (a.display_name === '' ? 1 : a.display_name < b.display_name ? -1 : a.display_name > b.display_name ? 1 : 0)

const removeStringBetweenBraces = (str: string) => {
    let result = `${str.substring(0, str.indexOf('{'))}${str.substring(str.indexOf('}') + 1)}`
    result.replace(' .', '.')
    return result
}

const FORMAT = 'YYYY-MM-DD'

const getPureSAPMessages = (et_return: any, type: 'E' | 'W'): string[] => {
    let result: string[] = []
    et_return.forEach((item: any) => {
        item.MESSAGE = removeStringBetweenBraces(item.MESSAGE)
        if (item.TYPE === type) {
            result.push(item.MESSAGE)
        }
    })
    return result
}

const getSAPMessages = (et_return: any, type: 'E' | 'W', employees: any): string[] => {
    let result: string[] = []
    et_return.forEach((item: any) => {
        item.MESSAGE = removeStringBetweenBraces(item.MESSAGE)
        if (item.TYPE === type) {
            if (item.MESSAGE.indexOf('[emp_pos]') > -1) {
                let pos = item.MESSAGE_V1
                let person: Person = employees.find((e: Person) => e.position_id === pos)
                if (person) {
                    item.MESSAGE = item.MESSAGE.replace('[emp_pos]', `<span class="emph">${person.prev_employee_name}</span>`)
                }
            }
            if (item.MESSAGE.indexOf('[sup_kerb_id]') > -1) {
                let person = employees[0]
                if (person) {
                    item.MESSAGE = item.MESSAGE.replace('[sup_kerb_id]', `<span class="emph">${person.supervisor}</span>`)
                }
            }
            if (item.MESSAGE.indexOf('[sup_pos]') > -1) {
                let person = employees[0]
                if (person) {
                    item.MESSAGE = item.MESSAGE.replace('[sup_pos] position', `<span class="emph">${person.supervisor}'s</span> position`)
                }
            }
            result.push(item.MESSAGE)
        }
    })
    return result
}

class LeaveApi extends BaseApi {
    readonly url_metadata: string
    readonly url_leave_supervisors: string
    readonly url_dept_employees: string
    readonly url_departments: string
    readonly url_update_supervisor: string
    readonly url_update_vacation_supervisor: string
    readonly url_supervised: string
    readonly url_supervisor: string
    readonly url_need_leave_supervisor: string
    readonly url_validate_leave_supervisor: string
    readonly url_can_change_supervisor: string
    readonly url_initialize: string
    readonly url_manager_relation: string
    readonly url_create_supervisor: string

    constructor() {
        super()
        this.url_metadata = `${this.apiHost}/sap-proxy-v2/metadata/Z_HR_BULK_GET_LEAVE_SUPERVISED`
        this.url_supervised = `${this.apiHost}/sap-proxy-v2/function`
        this.url_supervisor = `${this.apiHost}/sap-proxy-v2/function`
        this.url_leave_supervisors = `${this.apiHost}/sap-proxy-v2/function/Z_HR_BULK_GET_LEAVE_SUPERVISOR`
        this.url_dept_employees = `${this.apiHost}/sap-proxy-v2/function`
        this.url_departments = `${this.apiHost}/sap-proxy-v2/function`
        this.url_update_supervisor = `${this.apiHost}/sap-proxy-v2/function/Z_HR_BULK_UPD_LEAVE_SUPERVISOR`
        this.url_update_vacation_supervisor = `${this.apiHost}/sap-proxy-v2/function/Z_HR_BULK_UPD_VACA_SUPERVISOR`
        this.url_need_leave_supervisor = `${this.apiHost}/sap-proxy-v2/function/Z_HR_LSM_NEED_LEAVE_SUPERVISOR`
        this.url_validate_leave_supervisor = `${this.apiHost}/sap-proxy-v2/function/Z_HR_APR_VAL_SUPERVISOR`
        this.url_can_change_supervisor = `${this.apiHost}/sap-proxy-v2/function/Z_HR_CAN_CHANGE_SUPERVISOR`
        this.url_initialize = `${this.apiHost}/sap-proxy-v2/function/Z_PT_INITIALIZE`
        this.url_manager_relation = `${this.apiHost}/sap-proxy-v2/function/Z_PT_GET_MANAGER_RELATION`
        this.url_create_supervisor = `${this.apiHost}/sap-proxy-v2/function/Z_PT_CREATE_MANAGER_RELATION`
    }

    metadataRefresh = async (): Promise<FetchDepartmentsResponse> => {
        let response = await fetch(this.url_metadata, await this.requestHeaderGet())
        if (response.status === 200) {
            let result = await response.text()
            if (result) {
                return {
                    items: []
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchValidateEmployeeVacation = async (kerberos_id: string): Promise<ValidateEmployeeResponse> => {
        const canChangeResp = await this.fetchCanChangeSupervisor('VACA', kerberos_id, new Date())
        if (!canChangeResp.error && canChangeResp.result === true) {
            const initializeResp = await this.fetchInitialize(kerberos_id)
            if (!initializeResp.error) {
                return {
                    result: initializeResp.can_submit_vacation === true ? 'ALWAYS' : 'NEVER'
                }
            } else {
                return {
                    result: 'NEVER'
                }
            }
        } else {
            return {
                result: 'NOT_ALLOWED'
            }
        }
    }

    fetchValidateLeaveSupervisor = async (kerberos_id: string, date: Date, employees?: Person[]): Promise<FetchValidateLeaveSupervisorResponse> => {
        const rows = employees === undefined ? [] : employees.map((p: Person) => ({
            POSITION_ID: p.position_id,
            // BEGDA: p.,
            // ENDDA: p.,
            NAME: p.display_name,
            KERB: p.kerberos_id,
            MITID: p.mit_id,
            EMAIL: p.email,
            ORGUNIT: p.org_unit,
            ORGUNIT_NAME: p.org_unit_name,
            POSITION_TITLE: p.position_title,
            PERS_SUBAREA: p.pers_subarea,
            FIRST_NAME: p.first_name,
            LAST_NAME: p.last_name,
            SUP_POSITION: p.supervisor_position,
            SUP_POS_TITLE: p.supervisor_position_title
        }))
        const response = await fetch(this.url_validate_leave_supervisor, await this.requestHeaderPost({
            "Z_HR_APR_VAL_SUPERVISOR": {
                "import": {
                    "IV_EFF_DATE": `${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`,
                    "IV_SUPERV_KERBID": kerberos_id
                },
                "tables": {
                    "IT_SUPERVISED": {
                        "row": rows
                    }
                }
            }
        }))

        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                let errors: string[] = getPureSAPMessages(result.Z_HR_APR_VAL_SUPERVISOR.table.ET_RETURN.row, 'E')
                let warnings: string[] = getPureSAPMessages(result.Z_HR_APR_VAL_SUPERVISOR.table.ET_RETURN.row, 'W')
                if (errors.length > 0) {
                    return {
                        error: errors[0]
                    }
                } else {
                    return {
                        warnings: warnings,
                        positions: result.Z_HR_APR_VAL_SUPERVISOR.table.ET_SUP_POSITIONS.row.map((row: any) => {
                            return {
                                id: row['@id'],
                                kerberos: row['KERB'],
                                orgUnit: row['ORGUNIT'],
                                orgUnitName: row['ORGUNIT_NAME'],
                                persSubarea: row['PERS_SUBAREA'],
                                title: row['POSITION_TITLE'],
                                positionId: row['SUP_POSITION']
                            }
                        })
                    }
                }
            } else {
                return {
                    error: 'An error occurred and the supervisor could not be validated.'
                }
            }
        } else {
            return {
                error: 'An error occurred and the supervisor could not be validated.'
            }
        }
    }

    fetchNeedLeaveSupervisor = async (kerberos_id: string, date: Date): Promise<ValidateEmployeeResponse> => {
        const canChangeResp = await this.fetchCanChangeSupervisor('SICK', kerberos_id, date)
        if (!canChangeResp.error && canChangeResp.result === true) {
            let response = await fetch(this.url_need_leave_supervisor, await this.requestHeaderPost({
                "Z_HR_LSM_NEED_LEAVE_SUPERVISOR": {
                    "import": {
                        "IV_DATE": `${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`,
                        "IV_EMPL_KERBID": kerberos_id
                    }
                }
            }))
            if (response.status === 200) {
                let result = await response.json()
                if (result) {
                    return {
                        result: result.Z_HR_LSM_NEED_LEAVE_SUPERVISOR.export.EV_NEEDS_SUPERVISOR
                    }
                } else {
                    return {
                        error: result.Z_HR_LSM_NEED_LEAVE_SUPERVISOR.table.ET_RETURN.row
                    }
                }
            } else {
                return {
                    error: 'An error occurred and the supervisor could not be validated.'
                }
            }
        } else {
            return {
                result: 'NOT_ALLOWED'
            }
        }
    }

    fetchCanChangeSupervisor = async (type: string, kerberos_id: string, date: Date): Promise<FetchCanChangeSupervisorResponse> => {
        let response = await fetch(this.url_can_change_supervisor, await this.requestHeaderPost({
            "Z_HR_CAN_CHANGE_SUPERVISOR": {
                "import": {
                    "IV_DATE": `${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`,
                    "IV_EMPL_KERBID": kerberos_id,
                    "IV_RELATION_TYPE": type
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                return {
                    result: result.Z_HR_CAN_CHANGE_SUPERVISOR.export.IV_CAN_CHANGE === 'X'
                }
            } else {
                return {
                    error: result.Z_HR_CAN_CHANGE_SUPERVISOR.table.ET_RETURN.row
                }
            }
        } else {
            return {
                error: 'An error occurred and the supervisor could not be validated.'
            }
        }
    }

    fetchOrgUnits = async (rfc: string): Promise<FetchDepartmentsResponse> => {
        let response = await fetch(`${this.url_departments}/${rfc}`, await this.requestHeaderPost({
            rfc: {}
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                return {
                    items: result[rfc].table.ET_ORGUNIT.row.map((row: any) => ({
                        id: row.ORGEH,
                        org_unit: row.ORGEH,
                        org_unit_name: row.ORGTXT
                    }))
                }
            } else {
                return {
                    error: true
                }
            }
        } else if (response.status === 403) {
            return {
                error: true,
                errorText: USER_UNAUTHORIZED
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchLeaveOrgUnits = async (): Promise<FetchDepartmentsResponse> => {
        return this.fetchOrgUnits('Z_HR_BULK_GET_ORGUNITS')
    }

    fetchVacationOrgUnits = async (): Promise<FetchDepartmentsResponse> => {
        return this.fetchOrgUnits('Z_PT_BULK_GET_VACA_ORGUNITS')
        // return {items: []}
    }

    updateSupervisorValidateOnly = async (date: string, employees: any): Promise<FetchSaveSupervisorResponse> => {
        return this.updateSupervisor(date, employees, '', true)
    }

    processSupervisorUpdateResult = (etReturnRows: any, employees) => {
        if (etReturnRows.length > 0 && etReturnRows.find((row: any) => row.TYPE === 'E')) {
            return {error: getSAPMessages(etReturnRows, 'E', employees)}
        } else {
            let warnings = etReturnRows.length > 0 ? getSAPMessages(etReturnRows, 'W', employees) : []
            warnings = warnings.sort((a: string, b: string) => {
                if (a.startsWith('Supervisor')) {
                    return 1
                } else {
                    return a < b ? -1 : b < a ? 1 : 0
                }
            })
            return {
                warning: warnings
            }
        }
    }

    updateSupervisor = async (date: string, employees: any, supervisorPositionId: string, validateOnly?: boolean): Promise<FetchSaveSupervisorResponse> => {
        const rows = employees.map((e: any) => {
            return {
                "EMP_POSITION": e.position_id,
                "SUP_MITID": e.supervisor_mit_id,
                "SUP_KERBID": e.supervisor_kerberos_id,
                "SUP_NAME": e.supervisor,
                "SUP_POSITION": supervisorPositionId,
                "SUP_EMAIL": e.supervisor_email,
                "BEGDA": date,
                "ACTION": "MOD",
            }
        })
        let response = await fetch(this.url_update_supervisor, await this.requestHeaderPost({
            "Z_HR_BULK_UPD_LEAVE_SUPERVISOR": {
                "import": {
                    "IV_DATE": date,
                    "IV_VALIDATE_ONLY": validateOnly ? 'Y' : 'N'
                },
                "tables": {
                    "IT_SUPERVISOR_UPD": {
                        "row": rows
                    }
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (!result) {
                return {
                    error: ['An error has occurred and the supervisor(s) could not be updated.']
                }
            } else {
                return this.processSupervisorUpdateResult(result.Z_HR_BULK_UPD_LEAVE_SUPERVISOR.table.ET_RETURN.row, employees)
            }
        } else {
            let result = await response.json()
            return {
                error: result.message
            }
        }
    }

    updateVacationSupervisors = async (date: string, employees: any, action: string): Promise<FetchSaveSupervisorResponse> => {
        const rows = employees.map((e: any) => {
            return {
                "EMPLOYEE_KRBID": e.kerberos_id,
                "OLD_EMPLOYEE_KRBID": e.prev_employee_kerberos_id,
                "OLD_MANAGER_KRBID": e.prev_supervisor_kerberos_id,
                "MANAGER_KRBID": e.supervisor_kerberos_id,
                "START_DATE": date,
                "END_DATE": (new Date('9999-12-31').toISOString()),
                "ACTION": action
            }
        })
        let response = await fetch(this.url_update_vacation_supervisor, await this.requestHeaderPost({
            "Z_HR_BULK_UPD_VACA_SUPERVISOR": {
                "import": {
                    "IV_DATE": date
                },
                "tables": {
                    "IT_SUPERVISOR_UPD": {
                        "row": rows
                    }
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (!result) {
                return {
                    error: ['An error has occurred and the supervisor(s) could not be updated.']
                }
            } else {
                return this.processSupervisorUpdateResult(result.Z_HR_BULK_UPD_VACA_SUPERVISOR.table.ET_RETURN.row, employees)
            }
        } else {
            let result = await response.json()
            return {
                error: result.message
            }
        }
    }

    fetchSupervised = async (rfc: string, kerbId: string, date: Date): Promise<FetchSupervisedResponseAws> => {
        let response = await fetch(`${this.url_supervised}/${rfc}`, await this.requestHeaderPost({
            rfc: {
                "import": {
                    "IV_SUPERV_KERBID": kerbId,
                    "IV_DATE": `${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`,
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                return {
                    people: result[rfc].table.ET_SUPERVISED.row.map((row: any) => {
                        const person: Person = {
                            id: row.POSITION_ID,
                            display_name: `${row.FIRST_NAME} ${row.LAST_NAME}`,
                            first_name: `${row.FIRST_NAME}`,
                            last_name: `${row.LAST_NAME}`,
                            kerberos_id: row.KERB ? row.KERB.toLowerCase() : '',
                            mit_id: row.MITID,
                            email: row.EMAIL,
                            org_unit: row.ORGUNIT,
                            org_unit_name: row.ORGUNIT_NAME,
                            position_id: row.POSITION_ID,
                            position_title: row.POSITION_TITLE,
                            begin_date: row.BEGDA,
                            end_date: row.ENDDA,
                            pers_subarea: row.PERS_SUBAREA,
                            supervisor_kerberos_id: row.SUP_KERB,
                            supervisor_position: row.SUP_POSITION,
                            supervisor_position_title: row.SUP_POS_TITLE
                        }
                        return person
                    }).sort(sortName)
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchLeaveSupervised = async (kerbId: string, date: Date): Promise<FetchSupervisedResponseAws> => {
        return this.fetchSupervised('Z_HR_BULK_GET_LEAVE_SUPERVISED', kerbId, date)
    }

    fetchVacationSupervised = async (kerbId: string): Promise<FetchSupervisedResponseAws> => {
        return this.fetchSupervised('Z_PT_BULK_GET_VACA_SUPERVISED', kerbId, new Date())
    }

    fetchSupervisors = async (rfc: string, kerbId: string, date: Date): Promise<FetchSupervisorsResponse> => {
        let response = await fetch(`${this.url_supervisor}/${rfc}`, await this.requestHeaderPost({
            rfc: {
                "import": {
                    "IV_EMPL_KERBID": kerbId,
                    "IV_DATE": `${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`,
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                return {
                    people: result[rfc].table.ET_SUPERVISORS.row.map((row: any) => {
                        const person: Person = {
                            id: row.POSITION_ID,
                            display_name: `${row.FIRST_NAME} ${row.LAST_NAME}`,
                            first_name: `${row.FIRST_NAME}`,
                            last_name: `${row.LAST_NAME}`,
                            kerberos_id: row.KERB ? row.KERB.toLowerCase() : '',
                            mit_id: row.MITID,
                            email: row.EMAIL,
                            org_unit: row.ORGUNIT,
                            org_unit_name: row.ORGUNIT_NAME,
                            position_id: row.EMP_POSITION,
                            position_title: row.POSITION_TITLE,
                            begin_date: row.BEGDA,
                            end_date: row.ENDDA,
                            pers_subarea: row.PERS_SUBAREA,
                            emp_position: row.EMP_POSITION,
                            supervisor_position_title: row.SUP_POS_TITLE
                        }
                        return person
                    }).sort(sortName)
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchLeaveSupervisors = async (kerbId: string, date: Date): Promise<FetchSupervisedResponseAws> => {
        return this.fetchSupervisors('Z_HR_BULK_GET_LEAVE_SUPERVISOR', kerbId, date)
    }

    fetchVacationSupervisors = async (kerbId: string): Promise<FetchSupervisedResponseAws> => {
        return this.fetchSupervisors('Z_PT_BULK_GET_VACA_SUPERVISOR', kerbId, new Date())
    }

    fetchDepartmentEmployees = async (rfc: string, date: Date, departments: string[]): Promise<FetchSupervisorsResponse> => {
        let response = await fetch(this.url_dept_employees + '/' + rfc, await this.requestHeaderPost({
            rfc: {
                "import": {
                    "IV_DATE": `${MomentLocaleUtils.formatDate(date, FORMAT, 'en')}`,
                },
                "tables": {
                    "IT_ORGEH": {
                        "row": departments.map((s: string) => ({"ORGEH": s}))
                    }
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                return {
                    people: result[rfc].table.ET_EMP_SUP.row.map((row: any) => ({
                        id: row.EMP_POS,
                        display_name: `${row.EMP_FIRST_NAME} ${row.EMP_LAST_NAME}`,
                        first_name: row.EMP_FIRST_NAME,
                        last_name: row.EMP_LAST_NAME,
                        kerberos_id: row.EMP_KERB ? row.EMP_KERB.toLowerCase() : '',
                        mit_id: row.EMP_MITID,
                        email: row.EMP_EMAIL,
                        org_unit: row.EMP_ORGUNIT,
                        org_unit_name: row.EMP_ORGUNIT_NAME,
                        position_title: row.EMP_POSITION_TITLE,
                        supervisor: `${row.SUP_FIRST_NAME} ${row.SUP_LAST_NAME}`,
                        supervisor_kerberos_id: row.SUP_KERB,
                        supervisor_position: row.SUP_POS,
                        supervisor_position_title: row.SUP_POS_TITLE,
                        position_id: row.EMP_POS,
                        begin_date: row.SUP_BEGDA,
                        end_date: row.SUP_ENDDA,
                        pers_subarea: row.SUP_PERS_SUBAREA
                    })).sort(sortName)
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchDepartmentEmployeesLeave = async (date: Date, departments: string[]): Promise<FetchSupervisorsResponse> => {
        return this.fetchDepartmentEmployees('Z_HR_BULK_GET_ORGUNIT_EMP', date, departments)
    }

    fetchDepartmentEmployeesVacation = async (date: Date, departments: string[]): Promise<FetchSupervisorsResponse> => {
        return this.fetchDepartmentEmployees('Z_PT_BULK_GET_VACA_ORGUNIT_EMP', date, departments)
    }


    fetchInitialize = async (kerberos_id?: string): Promise<FetchInitializeResponse> => {
        let response = await fetch(this.url_initialize, await this.requestHeaderPost({
            "Z_PT_INITIALIZE": {
                "import": {
                    "IV_KERBID": kerberos_id ? kerberos_id : ''
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result) {
                return {
                    can_submit_vacation: result.Z_PT_INITIALIZE.export.EV_CAN_SUBMIT_VACA === 'X',
                    is_dept_vaca_admin: result.Z_PT_INITIALIZE.export.EV_IS_DEPT_VACA_ADMIN === 'X',
                    is_dept_sick_admin: result.Z_PT_INITIALIZE.export.EV_IS_DEPT_SICK_ADMIN === 'X',
                    is_sick_manager: result.Z_PT_INITIALIZE.export.EV_IS_SICK_MANAGER === 'X',
                    is_vaca_manager: result.Z_PT_INITIALIZE.export.EV_IS_VACA_MANAGER === 'X',
                    is_super_sick_admin: result.Z_PT_INITIALIZE.export.EV_IS_SUPER_SICK_ADMIN === 'X',
                    is_super_vaca_admin: result.Z_PT_INITIALIZE.export.EV_IS_SUPER_VACA_ADMIN === 'X'
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    createVacationSupervisor = async (employeeKerbId: string, managerKerbId: string): Promise<UpdateVacationSupervisorResponse> => {
        let response = await fetch(this.url_create_supervisor, await this.requestHeaderPost({
            "Z_PT_CREATE_MANAGER_RELATION": {
                "import": {
                    "IV_EMPLOYEE": employeeKerbId,
                    "IV_MANAGER": managerKerbId,
                    "IV_RELATION_TYPE": "VACA",
                }
            }
        }))
        if (response.status === 200) {
            let result = await response.json()
            if (result.Z_PT_CREATE_MANAGER_RELATION.export.EV_SUCCESS === 'X') {
                return {
                    error: false
                }
            } else {
                return {
                    error: true,
                    errorText: result.Z_PT_CREATE_MANAGER_RELATION.export.EV_MESSAGE
                }
            }
        } else {
            let result = await response.json()
            return {
                error: result.message
            }
        }
    }
}

const leaveApi = new LeaveApi()
export default leaveApi