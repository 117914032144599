import React, {useEffect, useState} from 'react';
import {FC} from "react";
import './_search.scss'
import {withRouter} from "react-router";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import SelectDepartment from "./select-department/select-department";
import leaveApi, {ValidateEmployeeResponse} from "../../api/leave-api";
import SelectPerson from "./select-person/select-person";
import DatePicker from "../form/DatePicker";
import MomentLocaleUtils from "react-day-picker/moment";
import {validateEffectiveDate} from "../form/validations";

interface SearchMainInterface {
    history?: any;
}

const SearchMain: FC<SearchMainInterface> = ({history}) => {
    const dispatch = useAppDispatch()
    const {appState} = useAppState()

    const [leaveEmployeeError, setLeaveEmployeeError] = useState<string>('')
    const [vacationEmployeeError, setVacationEmployeeError] = useState<string>('')
    const [leaveSupervisorError, setLeaveSupervisorError] = useState<string>('')
    const [vacationSupervisorError, setVacationSupervisorError] = useState<string>('')
    const [date, setDate] = useState<Date>(new Date())
    const NOT_ALLOWED = 'No Access To View This Person'
    const NOT_ELIGIBLE_EMP = 'No supervisor exists'
    const NOT_ELIGIBLE_SUPV = 'Not Eligible to be Supervisor'
    const NOT_ACTIVE_APPT = 'No Paid Appointment on'

    function performEmployeeValidation(result: 'ALWAYS' | 'NEVER' | 'NOT_ALLOWED' | 'NONPAID' | undefined, textUpdateFn): boolean {
        if (typeof result === 'undefined')
            return false

        let valid = true
        if (result === "NOT_ALLOWED") {
            textUpdateFn(NOT_ALLOWED)
            valid = false
        } else if (result === 'ALWAYS') {
            textUpdateFn('')
        } else if (result === 'NONPAID') {
            textUpdateFn(`${NOT_ACTIVE_APPT} ${MomentLocaleUtils.formatDate(date, 'MM/DD/YYYY', 'en')}`)
            valid = false
        } else {
            textUpdateFn(NOT_ELIGIBLE_EMP)
            valid = false
        }
        return valid
    }

    function validateLeaveEmployee(validationResult: ValidateEmployeeResponse): boolean {
        return performEmployeeValidation(validationResult.result, setLeaveEmployeeError)
    }

    function validateVacationEmployee(validationResult: ValidateEmployeeResponse): boolean {
        return performEmployeeValidation(validationResult.result, setVacationEmployeeError)
    }

    function validateLeaveSupervisor(validationResult: any): boolean {
        let res = !validationResult.error
        setLeaveSupervisorError(validationResult.error ? NOT_ELIGIBLE_SUPV : '')
        return res
    }

    function validateVacationSupervisor(validationResult: any): boolean {
        let res = !validationResult.error
        setVacationSupervisorError(validationResult.error ? NOT_ELIGIBLE_SUPV : '')
        return res
    }

    function onSubmitLeaveEmployee() {
        callDispatch('search', 'search_employee')
        callDispatch('input_supervisor', undefined)
        callDispatch('input_department_vacation', [])
        callDispatch('input_department_leave', [])
        updateRoute('employee', appState.inputEmployee!.kerberos_id!)
    }

    function onSubmitSupervisor() {
        callDispatch('search', 'search_supervisor')
        callDispatch('input_employee', undefined)
        callDispatch('input_department_vacation', [])
        callDispatch('input_department_leave', [])
        updateRoute('supervisor', appState.inputSupervisor!.kerberos_id!)
    }

    function callDispatch(type, value) {
        dispatch({type, value})
    }

    function updateRoute(path: string, endpoint?: string) {
        if (endpoint)
            history.push(`/${appState.active_tab}/${path}/${endpoint}`)
        else
            history.push(`/${path}`)
    }

    useEffect(() => {
        callDispatch('input_effective_date', date)
        // eslint-disable-next-line
    }, [date])

    return <div className={'search-main'}>
        {appState.active_tab === 'leave' &&
        <div className={'search-container'}>
            <div className={'search-section'}>
                <h2 className={'search-section-heading'}>Effective Date</h2>
                <DatePicker name={'effective_date'} handleChange={(newDate) => {
                    setDate(newDate)
                }} defaultValue={date} validateFn={validateEffectiveDate} onlyFuture={true}/>
            </div>
        </div>}
        <div className={'search-container'}>
            <div className={'search-section'}>
                <h2 className={'search-section-heading'}>Supervisor</h2>
                <p className={'search-section-description'}>View a supervisor's employees</p>
                {appState.active_tab === 'leave' &&
                <SelectPerson id={'input_supervisor_leave'} type={'input_supervisor'}
                              inputPerson={appState.inputSupervisor}
                              onSubmit={onSubmitSupervisor}
                              validationErrorText={leaveSupervisorError}
                              effectiveDate={date}
                              validateFetchData={leaveApi.fetchValidateLeaveSupervisor}
                              validatePerform={validateLeaveSupervisor}
                              updateAppState={callDispatch}/>}
                {appState.active_tab === 'vacation' &&
                <SelectPerson id={'input_supervisor_vacation'} type={'input_supervisor'}
                              inputPerson={appState.inputSupervisor}
                              onSubmit={onSubmitSupervisor}
                              effectiveDate={new Date()}
                              validationErrorText={vacationSupervisorError}
                              validateFetchData={leaveApi.fetchValidateLeaveSupervisor}
                              validatePerform={validateVacationSupervisor}
                              updateAppState={callDispatch}/>}
            </div>
            <div className={'search-section'}>
                <h2 className={'search-section-heading'}>Employee</h2>
                <p className={'search-section-description'}>View an employee's supervisor(s)</p>
                {appState.active_tab === 'leave' && <SelectPerson id={'input_employee_leave'} type={'input_employee'}
                                                                  inputPerson={appState.inputEmployee}
                                                                  onSubmit={onSubmitLeaveEmployee}
                                                                  effectiveDate={date}
                                                                  validationErrorText={leaveEmployeeError}
                                                                  validateFetchData={leaveApi.fetchNeedLeaveSupervisor}
                                                                  validatePerform={validateLeaveEmployee}
                                                                  updateAppState={callDispatch}/>}
                {appState.active_tab === 'vacation' &&
                <SelectPerson id={'input_employee_vacation'} type={'input_employee'}
                              inputPerson={appState.inputEmployee}
                              effectiveDate={new Date()}
                              onSubmit={onSubmitLeaveEmployee}
                              validationErrorText={vacationEmployeeError}
                              validateFetchData={leaveApi.fetchValidateEmployeeVacation}
                              validatePerform={validateVacationEmployee}
                              updateAppState={callDispatch}/>}
            </div>
            <div className={'search-section'}>
                <h2 className={'search-section-heading'}>Department</h2>
                {appState.active_tab === 'leave' &&
                <SelectDepartment options={appState.departmentsLeave} updateRoute={updateRoute}
                                  inputDepartment={appState.inputDepartmentLeave}/>}
                {appState.active_tab === 'vacation' &&
                <SelectDepartment options={appState.departmentsVacation} updateRoute={updateRoute}
                                  inputDepartment={appState.inputDepartmentVacation}/>}
            </div>
        </div>
    </div>
}

export default withRouter(SearchMain)

