import * as React from 'react'
import {FC, useState} from "react";
import './_delete_supervisor.scss'
import AprModal from "@mit/apr-modal/apr-modal";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import leaveApi from "../../api/leave-api";

const DeleteSupervisor: FC<any> = ({dispatchType, field, selection}) => {
    const dispatch = useAppDispatch()
    const {appState} = useAppState()

    const [hasError, setHasError] = useState<boolean>(false)
    const [error, setError] = useState<string[]>([])
    function closeModal() {
        dispatch({type: 'delete_supervisor', value: false})
    }
    async function onSubmit() {
        const employees = JSON.parse(JSON.stringify(appState[field]))
        const filtered = employees.filter((e: any) => e.selected === true)
        const updateResult = await leaveApi.updateVacationSupervisors((new Date()).toISOString(), filtered, 'DEL')

        if (updateResult.error) {
            setHasError(true)
            setError(updateResult.error)
        } else {
            dispatch({type: 'delete_supervisor', value: false})
            dispatch({type: 'supervisor_delete_success', value: true})
            dispatch({type: 'set_must_refresh', value: true})
        }
    }
    return <AprModal heading={'Delete Supervisor'} onClose={closeModal} onSubmit={onSubmit}>
        <div>
            {hasError && <div className={'alert error'}>{error}</div>}

            <label className={'input-label top'}>Are you sure you want to remove the selected supervisor(s)?</label>
        </div>

    </AprModal>
}

export default DeleteSupervisor