import * as React from 'react'
import Maintain from "./maintain";
import SelectTable from "../table/select-table";
import ChangeSupervisor from "../change-supervisor/change-supervisor";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import ChangeButton from "../change-supervisor/change-button";
import DeleteSupervisor from "../delete-supervisor/delete-supervisor";
import {useCallback, useEffect, useState} from "react";
import leaveApi from "../../api/leave-api";
import {useParams} from "react-router";
import {Person} from "../../model/person.model";
import userApi from "../../api/user-api";
import {
    retrieveProfile, validateCanChangeSupervisor,
    validateEmployeeEligibility
} from "./employee-supervisors-validations";
import EffectiveDate from "../effective-date/effective-date";
import MomentLocaleUtils from "react-day-picker/moment";

const EmployeeSupervisors = () => {
    const params = useParams()
    const {appState} = useAppState()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
    const [employeeEligibility, setEmployeeEligibility] = useState<boolean | undefined>(undefined)
    const employeeListColumns = [
        {title: 'First Name', id: 'first_name'},
        {title: 'Last Name', id: 'last_name'},
        {title: 'MIT ID', id: 'mit_id'},
        {title: 'Kerb ID', id: 'kerberos_id'},
        {title: 'Supv. Position Title', id: 'supervisor_position_title'},
        {title: 'Pers. Subarea', id: 'pers_subarea'},
        {title: 'Department', id: 'org_unit_name'},
        {title: 'Emp. Position Title', id: 'position_title'}
    ]
    const selected = appState.employeeSupervisors ? appState.employeeSupervisors.filter((item: any) => item.selected === true) : []

    useEffect(() => {
        dispatch({type: 'set_active_tab', value: params.active_tab})
    }, [dispatch, params.active_tab])

    const fetchData = useCallback((kerberos_id: string, date: Date) => {
        dispatch({type: 'set_is_refreshing', value: true})
        const api = appState.active_tab === 'leave' ? leaveApi.fetchLeaveSupervisors : leaveApi.fetchVacationSupervisors
        api(kerberos_id, date).then((resp) => {
            if (!resp.error) {
                const people = resp.people && resp.people.length > 0 ? resp.people.map((item: any) => {
                    item.selected = false
                    return item
                }) : []
                dispatch({
                    type: 'update_employee_supervisors', value: people
                })
            } else {

            }
            dispatch({type: 'set_is_refreshing', value: false})
            setLoading(false)
        })
    }, [dispatch, appState.active_tab])

    useEffect(() => {
        setLoading(true)
        const validateEmployee = async () => {
            const isAuthorized = await validateCanChangeSupervisor(appState.active_tab, params.kerberos_id, appState.inputEffectiveDate)
            const isEligible = await validateEmployeeEligibility(appState.active_tab, params.kerberos_id, appState.inputEffectiveDate)
            const userResp = await userApi.fetchProfile(params.kerberos_id)
            const profileResponse = await retrieveProfile(userResp)
            if (profileResponse !== -1) {
                dispatch({type: 'input_employee', value: profileResponse})
            }
            setIsValid(isEligible && isAuthorized)
            setEmployeeEligibility(isEligible)
        }
        validateEmployee().then(() => {
            if (isValid) {
                userApi.fetchProfile(params.kerberos_id).then((userResp) => {
                    if (!userResp.error) {
                        const p: Person = userResp.person ? {
                            display_name: userResp.person.displayName,
                            kerberos_id: userResp.person.kerberos_id,
                            search_name: `${userResp.person.displayName} (${userResp.person.kerberos_id})`
                        } : {
                            display_name: params.kerberos_id,
                            kerberos_id: params.kerberos_id,
                            search_name: params.kerberos_id
                        }
                        dispatch({type: 'input_employee', value: p})
                        fetchData(params.kerberos_id, appState.inputEffectiveDate)
                    }
                    dispatch({type: 'search', value: 'search_employee'})
                })
            } else {
                setLoading(false)
            }
        })

        return () => {
            dispatch({type: 'set_is_refreshing', value: false})
        }
    }, [dispatch, fetchData, appState.active_tab, params.kerberos_id, isValid, appState.inputEffectiveDate])

    useEffect(() => {
        if (appState.mustRefresh) {
            fetchData(params.kerberos_id, appState.inputEffectiveDate)
            dispatch({type: 'set_must_refresh', value: false})
        }

        return () => {
            dispatch({type: 'set_is_refreshing', value: false})
        }
    }, [fetchData, dispatch, appState.mustRefresh, params.kerberos_id, appState.inputEffectiveDate])

    if (isValid === false) {
        return <div>
            <Maintain/>
            {employeeEligibility === false ? <div>
                The employee does not have an active appointment on {MomentLocaleUtils.formatDate(appState.inputEffectiveDate, 'MM/DD/YYYY', 'en')}.
            </div> : <div>
                You are not authorized to change the selected person.
            </div>}
        </div>
    } else {
        return !loading && appState.inputEmployee ? <div>
            <Maintain/>
            <EffectiveDate />
            {appState.isChangingSupervisor &&
            <ChangeSupervisor field={'employeeSupervisors'} dispatchType={'update_employee_supervisors'}
                              selection={selected}/>}
            {appState.isDeletingSupervisor &&
            <DeleteSupervisor field={'employeeSupervisors'} dispatchType={'update_employee_supervisors'}
                              selection={selected}/>}
            {appState.isRefreshing ? <div>Loading...</div> : <SelectTable
                dispatchType={'update_employee_supervisors'}
                columns={employeeListColumns} rows={appState.employeeSupervisors}
                actions={[<ChangeButton key={'emp-supv-1'} disabled={selected.length === 0}/>]}/>}
        </div> : <div>Loading...</div>
    }


}

export default EmployeeSupervisors