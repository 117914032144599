import React, {useEffect, useState} from 'react';
import SearchMain from "./components/search-main/search-main";
import './sass/_app.scss'
import SupervisorEmployees from "./components/maintain/supervisor-employees";
import EmployeeSupervisors from "./components/maintain/employee-supervisors";
import DepartmentEmployees from "./components/maintain/department-employees";
import {useAppDispatch, useAppState} from "./context/app/app-context";
import Header from "./components/header/header";
import userApi from "./api/user-api";
import {Switch, Route, BrowserRouter, Redirect} from "react-router-dom";
import Admin from "./admin";
import leaveApi from "./api/leave-api";
import AprTabs from "./components/apr-tabs/AprTabs";
import AprTab from "./components/apr-tabs/AprTab";
import featureApi from "./api/feature-api";
import AUTH_TYPES from "./model/authorizations.types";
import {USER_UNAUTHORIZED} from "./language";

function populateDepartments(dispatch: any, auths: any, authType: string, dispatchAction: string, departmentResponse: any) {
    if (!departmentResponse.error) {
        const items = departmentResponse.items.map((item) => {
            return {
                value: item.org_unit,
                label: item.org_unit_name,
            }
        })
        if (departmentResponse.items.length > 0) {
            auths.push(authType)
        }
        dispatch({type: dispatchAction, value: items})
    } else {
        dispatch({type: 'update_app_error', value: true})
        dispatch({type: 'update_app_error_text', value: departmentResponse.errorText})
    }
}

function App() {
    const {appState} = useAppState()
    const dispatch = useAppDispatch()

    const [isActive, setIsActive] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (appState.authorizations && !appState.active_tab) {
            if(appState.authorizations.includes(AUTH_TYPES.sick_admin)) {
                dispatch({type: 'set_active_tab', value: 'leave'})
            } else if(appState.authorizations.includes(AUTH_TYPES.vaca_admin)) {
                dispatch({type: 'set_active_tab', value: 'vacation'})
            }
        }
    }, [dispatch, appState.active_tab, appState.authorizations])

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const profileResponse = await userApi.fetchProfile()
            dispatch({type: 'set_profile', value: profileResponse.person})
            const featuresResponse = await featureApi.fetchFeatures()
            if (!featuresResponse.error) {
                setIsActive(featuresResponse.features.active)
                let auths: string[] = []
                if(featuresResponse.features.vacation_active){
                    const timePortalInitializeResponse = await leaveApi.fetchInitialize()
                    if (!timePortalInitializeResponse.error) {
                        if (timePortalInitializeResponse.is_super_sick_admin === true) {
                            auths.push(AUTH_TYPES.sick_admin)
                        }
                        if (timePortalInitializeResponse.is_dept_vaca_admin === true ||
                            timePortalInitializeResponse.is_super_vaca_admin === true) {
                            auths.push(AUTH_TYPES.vaca_admin)
                        }
                    }
                }

                const departmentResponse = await leaveApi.fetchLeaveOrgUnits()
                populateDepartments(dispatch, auths, AUTH_TYPES.sick_admin, 'update_departments_leave', departmentResponse)
                if(featuresResponse.features.vacation_active && auths.includes(AUTH_TYPES.vaca_admin)){
                    const vacationDepartmentResponse = await leaveApi.fetchVacationOrgUnits()
                    populateDepartments(dispatch, auths, AUTH_TYPES.vaca_admin, 'update_departments_vacation', vacationDepartmentResponse)
                }
                dispatch({type: 'update_authorized', value: auths})
            } else {
                setIsActive(false)
                dispatch({type: 'set_app_loaded', value: true})
            }
        }
        fetchData()
            .then(() => {
                dispatch({type: 'set_app_loaded', value: true})
            })
            .catch(() => {
                setIsActive(false)
                dispatch({type: 'set_app_loaded', value: true})
            })
    }, [dispatch])

    function onClickTab(id: 'leave' | 'vacation'): void {
        dispatch({type: 'set_active_tab', value: id})
        dispatch({type: 'input_employee', value: undefined})
        dispatch({type: 'input_supervisor', value: undefined})
    }

    return (<>
            <Header/>
            {!appState.appLoaded && <div className="app-content">Loading...</div>}
            {!isActive && appState.appLoaded && <div className="app-content">The application is not currently available.</div>}
            {isActive && appState.appLoaded && <div className="app-content">
                {appState.appError && <div>{appState.appErrorText && appState.appErrorText.length > 0 ? appState.appErrorText : `An error occurred and the application could not be loaded. Please contact the Help Desk for assistance.`}</div>}
                {!appState.appError && appState.authorizations && appState.authorizations.length === 0 && <div>{USER_UNAUTHORIZED}</div>}
                {!appState.appError && appState.authorizations && appState.authorizations.length > 0 && <BrowserRouter>
                    <Switch>
                        <Route exact path={'/:active_tab/supervisor/:kerberos_id'}>
                            <SupervisorEmployees/>
                        </Route>
                        <Route exact path={'/:active_tab/employee/:kerberos_id'}>
                            <EmployeeSupervisors/>
                        </Route>
                        <Route exact path={'/:active_tab/department/:org_units'}>
                            <DepartmentEmployees/>
                        </Route>
                        <Route exact path={'/admin'}>
                            <Admin/>
                        </Route>
                        <Route exact path={'/'}>
                            {!appState.activeSearch && <header>
                                <h1 className={'page-heading'}>Supervisor Maintenance</h1>
                            </header>}
                            {appState.authorizations.length > 0 && <AprTabs onClickTab={onClickTab} defaultTab={appState.active_tab}>
                                {appState.authorizations.find((s: string) => s === AUTH_TYPES.sick_admin) && <AprTab id={'leave'} title={'Sick Time'}>
                                    <SearchMain/>
                                </AprTab>}
                                {appState.authorizations.find((s: string) => s === AUTH_TYPES.vaca_admin) && <AprTab id={'vacation'} title={'Vacation Time'}>
                                    <SearchMain/>
                                </AprTab>}
                            </AprTabs>}
                        </Route>
                        <Redirect to={'/'}/>
                    </Switch>
                </BrowserRouter>
                }
            </div>}
        </>
    );
}

export default App;
