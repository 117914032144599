import * as React from 'react'
import {FC, useState, useCallback} from 'react'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import peopleSearchApi from "../../api/people-search-api";
import {Person} from "../../model/person.model";
import './people-search.scss'

const PeopleSearch: FC<any> = ({id, onSelect, defaultPerson}) => {
    const [options, setOptions] = useState<Person[] | undefined>(undefined)
    const [selected, setSelected] = useState<Person | undefined>(defaultPerson && defaultPerson.hasOwnProperty('kerberos_id') ? defaultPerson : undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSearch = useCallback(async (query) => {
        setIsLoading(true)
        let fetchPeopleResponse = await peopleSearchApi.fetchPeople(query)
        if (!fetchPeopleResponse.error) {
            if (options) {
                const flag = {}
                const unique: Person[] = []
                let duplicates = fetchPeopleResponse.people ? options.concat(fetchPeopleResponse.people) : options
                duplicates.forEach((item: Person) => {
                    if (!flag[item.kerberos_id!]) {
                        flag[item.kerberos_id!] = true
                        unique.push(item)
                    }
                })
                setOptions(unique)
            } else {
                setOptions(fetchPeopleResponse.people)
            }
        }
        setIsLoading(false)
    }, [options])

    const handleSelect = async (selected) => {
        if (selected.length > 0) {
            setSelected(selected[0])
            onSelect(selected[0])
        } else {
            onSelect(undefined)
        }
    }

    return <AsyncTypeahead
        id={`type-ahead-${id}`}
        isLoading={isLoading}
        defaultSelected={selected ? [selected] : undefined}
        labelKey="search_name"
        minLength={3}
        delay={300}
        useCache={false}
        onSearch={handleSearch}
        onChange={handleSelect}
        options={options}
        clearButton
        emptyLabel={<div className={'search-help'}>
            <div>No matches found.</div>
            <div className={'search-help-detail'}>
                <div>3 characters are required to initiate a search. E.g. to find "Li Qian", type "li q" to see
                    results.
                </div>
            </div>
        </div>}
        placeholder="Type name or kerberos id..."
        renderMenuItemChildren={(option, props) => (
            <div><span>{option.search_name}</span></div>
        )}
        filterBy={(option, props) => {
            const text = props.text ? props.text.toLowerCase() : ''
            return (option.first_name + ' ' + option.last_name).toLowerCase().indexOf(text) > -1 ||
                option.display_name.toLowerCase().indexOf(text) > -1 ||
                option.kerberos_id.toLowerCase().indexOf(text) > -1
        }}
    >
        {/*{isLoading && <div className="rbt-spinner"><Spinner className={'input-spinner'}/></div>}*/}
    </AsyncTypeahead>
}

export default PeopleSearch