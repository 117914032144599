import * as React from 'react'
import {FC} from "react";
import './_alert-list.scss'
interface AlertListProps {
    text: string[];
    type: 'E' | 'W'
}

const AlertList: FC<AlertListProps> = ({text, type}) => {
    let set = new Set(text)
    text = [...set]
    return <div className={`alert ${type === 'E' ? 'error' : 'warning'}`}>
        {type === 'E' && <h2>Error</h2>}
        {type === 'W' && <h2>Warning</h2>}
        {text.map((t: string, i: number) => {
        return <div key={i}>
            <div className={'warning-line'} dangerouslySetInnerHTML={{__html: t}}/>
        </div>
    })}</div>
}
export default AlertList