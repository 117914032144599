import SelectDepartments from "../../department/select-departments";
import DepartmentModel from "../../../model/department.model";
import React from "react";
import {useAppDispatch} from "../../../context/app/app-context";

const SelectDepartment = ({options, updateRoute, inputDepartment}) => {
    const dispatch = useAppDispatch()
    return <>
        <p className={'search-section-description'}>
            {options && options.length === 0 ? <span>You don't have any departments</span> : <span> View or update all relationships by department </span>}
        </p>
        <div className={'search-component'}>
            <div className={'search-controls'}>
                {options && <SelectDepartments options={options}/>}
                {!options && <div className={'dept-loading'}>Loading...</div>}
                <button disabled={!inputDepartment || (inputDepartment && inputDepartment.length === 0)} onClick={() => {
                    dispatch({type: 'search', value: 'search_department'})
                    dispatch({type: 'input_employee', value: undefined})
                    dispatch({type: 'input_supervisor', value: undefined})
                    updateRoute('department', inputDepartment.map((value: DepartmentModel) => value.value).join('-'))
                }}>Submit
                </button>
            </div>
        </div>
    </>
}

export default SelectDepartment