import * as React from 'react'
import {SearchTypes} from "../../model/search-types";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import {FC, useEffect} from "react";
import {withRouter} from "react-router";
import './_maintain.scss'
import {ChangedPerson} from "../../model/changed-person-interface";

const Maintain: FC<any> = ({history}) => {
    const {appState: contextAppState} = useAppState()
    const dispatch = useAppDispatch()

    const appState = {...contextAppState} // new AppState(contextAppState.inputSupervisor, contextAppState.inputEmployee, contextAppState.inputDepartment, contextAppState.activeSearch, contextAppState.isChangingSupervisor, contextAppState.supervisorChanged, contextAppState.supervisorEmployees, contextAppState.employeeSupervisors, contextAppState.departmentEmployees, contextAppState.isDeletingSupervisor, contextAppState.supervisorDeleted, contextAppState.departments)

    function getDepartmentString(): string {
        const deps = appState.active_tab === 'leave' ? appState.inputDepartmentLeave : appState.inputDepartmentVacation
        let s = deps.map(({label}) => label).join(deps.length === 2 ? ' and ' : ', ')
        return s
    }

    function getPageTitle(lowerCase?: boolean): string {
        let res = ''
        if (appState.active_tab === 'leave') {
            res = 'Sick Time'
        } else {
            res = 'Vacation Time'
        }
        return lowerCase ? res.toLowerCase() : res
    }

    function renderTitle(activeSearch: SearchTypes) {
        switch (activeSearch) {
            case "search_department":
                if (!appState.inputDepartmentLeave && !appState.inputDepartmentVacation)
                    return 'Leave Supervisor Maintenance'
                if ((appState.inputDepartmentLeave && appState.inputDepartmentLeave.find(({
                                                                                              label,
                                                                                              value
                                                                                          }) => value === 'all')) ||
                    (appState.inputDepartmentVacation && appState.inputDepartmentVacation.find(({
                                                                                                    label,
                                                                                                    value
                                                                                                }) => value === 'all')))
                    return 'Employees in all departments'
                return <div>{getPageTitle()}: Department Employees
                    <div className={'department-list'}>{getDepartmentString()}</div>
                </div>
            case "search_employee":
                if (appState.inputEmployee) {
                    return `${appState.inputEmployee.display_name}'s ${getPageTitle()} Supervisor(s)`
                }
                break
            case "search_supervisor":
                if (appState.inputSupervisor) {
                    return `Employees reporting ${getPageTitle()} to ${appState.inputSupervisor.display_name}`
                }
                break
            default:
                return `Leave Supervisor Maintenance`
        }
    }

    useEffect(() => {
        return () => {
            dispatch({type: "supervisor_changed_success", value: false})
        }
    }, [dispatch, appState.activeSearch])

    return <div>
        <h1 className={'page-heading'}>{renderTitle(appState.activeSearch)}</h1>
        <button className={'link-button'} onClick={() => {
            dispatch({type: 'search', value: ''})
            history.push('/')
        }}>&lt; Back
        </button>
        {appState.supervisorChanged === true && <div className={'alert success'}>
            <h2>The change has been processed successfully.</h2>
            <div className={'changed-person-detail'}>
                {contextAppState.changedSupervisors && contextAppState.changedSupervisors.map((changedPerson: ChangedPerson) => {
                    return <div className={'changed-person-item'}>
                        <strong>{changedPerson.employeeName}'s</strong> supervisor changed from <strong>{changedPerson.oldSupervisor}</strong> to <strong>{changedPerson.newSupervisor}</strong>.
                    </div>
                })}
            </div>
        </div>}
        {appState.supervisorAdded === true && <div className={'alert success'}>
            The relationship has been created successfully.
        </div>}
        {appState.supervisorChangedWarning === true && <div className={'alert warning'}>
            {appState.supervisorChangedWarningText}
        </div>}
    </div>
}

export default withRouter(Maintain)