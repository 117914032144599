import React, {FC, useState} from "react";
import PeopleSearch from "../../people-search/people-search";
import {Person} from "../../../model/person.model";

interface SelectPersonProps {
    id: string;
    type: 'input_supervisor' | 'input_employee';
    onSubmit: () => void;
    inputPerson?: Person;
    validateFetchData: (kerberos_id: string, date: Date) => Promise<any>;
    validatePerform: (data: any) => boolean;
    updateAppState: (type: any, value: any) => void;
    validationErrorText: string;
    effectiveDate: Date;
}

const SelectPerson: FC<SelectPersonProps> = ({
                                                 id,
                                                 type,
                                                 onSubmit,
                                                 inputPerson,
                                                 validateFetchData,
                                                 validatePerform,
                                                 updateAppState,
                                                 validationErrorText,
                                                 effectiveDate
                                             }) => {
    const [isValidating, setIsValidating] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean | undefined>()

    async function handleSelect(type: string, value: Person) {
        setIsValid(undefined)
        if (value && value.kerberos_id) {
            setIsValidating(true)
            let validationResult = await validateFetchData(value.kerberos_id, effectiveDate)
            setIsValidating(false)
            if (!validationResult.error) {
                let isValueValid = validatePerform(validationResult)
                setIsValid(isValueValid)
                if (isValueValid) {
                    updateAppState(type, value)
                }
            } else {
                setIsValid(false)
                validatePerform(validationResult)
            }
        }
    }

    return <div className={'search-component'} key={id}>
        <div className={'search-controls'}>
            <PeopleSearch id={type} defaultPerson={inputPerson}
                          onSelect={(person) => handleSelect(type, person)}/>
            <button disabled={!inputPerson || !isValid} onClick={onSubmit}>Submit</button>
        </div>
        {isValidating && <div>Validating...</div>}
        {isValid === false && <div className={'form-error-text'}>{validationErrorText ? validationErrorText : 'Person not eligible'}</div>}
    </div>
}

export default SelectPerson