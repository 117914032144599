import * as React from 'react'
import {FC, useEffect, useState} from "react";
import './_add_supervisor.scss'
import AprModal from "@mit/apr-modal/apr-modal";
import {useAppDispatch} from "../../context/app/app-context";
import PeopleSearch from "../people-search/people-search";
import leaveApi from "../../api/leave-api";

const AddSupervisor: FC<any> = ({dispatchType, field, selection}) => {
    const dispatch = useAppDispatch()
    const [selectedEmployee, setSelectedEmployee] = useState<any>(undefined)
    const [selectedSupervisor, setSelectedSupervisor] = useState<any>(undefined)
    const [hasError, setHasError] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    useEffect(() => {
        dispatch({type: 'supervisor_added_success', value: false})
    }, [dispatch])

    function closeModal() {
        dispatch({type: 'add_supervisor', value: false})
    }

    async function onSubmit() {
        if (selectedEmployee && selectedSupervisor) {
            setHasError(false)
            setError('')
        } else {
            setHasError(true)
            setError('Please complete all the fields and try again.')
            return
        }
        const updateResult = await leaveApi.createVacationSupervisor(selectedEmployee.kerberos_id, selectedSupervisor.kerberos_id)
        if (updateResult.error) {
            setHasError(true)
            setError(updateResult.errorText ? updateResult.errorText : 'An error has occurred and the relationship cannot be created.')
        } else {
            dispatch({type: 'add_supervisor', value: false})
            dispatch({type: 'supervisor_added_success', value: true})
            dispatch({type: 'set_must_refresh', value: true})
        }
    }

    function handleSelect(onSelect, value) {
        onSelect(value)
    }

    return <AprModal heading={'Add Vacation Supervisor'} onClose={closeModal} onSubmit={onSubmit}>
        {hasError && <div className={'alert error'}>{error}</div>}
        <div className={'flex'}>
            <div>
                <label className={'input-label top'}>Employee</label>
                <PeopleSearch id={'input_employee'} defaultPerson={undefined}
                              onSelect={(person) => handleSelect(setSelectedEmployee, person)}/>
            </div>
            <div>
                <label className={'input-label top'}>Supervisor</label>
                <PeopleSearch id={'input_supervisor'} defaultPerson={undefined}
                              onSelect={(person) => handleSelect(setSelectedSupervisor, person)}/>
            </div>
            {/*<div>*/}
            {/*    <label className={'input-label top'}>Effective Date</label>*/}
            {/*    <DatePicker name={'effective_date'} handleChange={setDate} defaultValue={''}/>*/}
            {/*</div>*/}
        </div>

    </AprModal>
}

export default AddSupervisor