import * as React from 'react'
import Select from 'react-select'
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import DepartmentModel from "../../model/department.model";
/* https://react-select.com/home */
const SelectDepartments = ({options}) => {
    const dispatch = useAppDispatch()
    const {appState} = useAppState()

    const onSelectChange = (values: DepartmentModel[]) => {
        if(appState.active_tab === 'leave') {
            dispatch({type: "input_department_leave", value: values})
        } else {
            dispatch({type: "input_department_vacation", value: values})
        }
    }
    return <Select placeholder={options.length > 0 ? 'Select...' : 'No Options '} defaultValue={appState.active_tab === 'leave' ? appState.inputDepartmentLeave : appState.inputDepartmentVacation} onChange={onSelectChange} isMulti options={options} className="basic-multi-select" />
}

export default SelectDepartments