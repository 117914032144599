import * as React from 'react'
import {FC, useEffect, useState} from "react";
import './_select-table.scss'
import {useAppDispatch} from "../../context/app/app-context";
import Filters from "./filters";
import {FilterItem, SelectTableProps, SortItem} from "./select-table-types";

interface SortIconInterface {
    sortValue?: number;
}

const SortIcon: FC<SortIconInterface> = ({sortValue}) => (<div>
    {!sortValue && <div className={'sort-icon-none'}>_</div>}
    {sortValue === 1 && <img className={'sort-icon'} src='/glyphicons-basic-476-sort-attributes.svg' alt={'Ascending'}/>}
    {sortValue === -1 && <img className={'sort-icon'} src='/glyphicons-basic-477-sort-attributes-alt.svg' alt={'Ascending'}/>}
</div>)

const SelectTable: FC<SelectTableProps> = ({columns, rows, defaultSort, actions, dispatchType}) => {
    const [localRows, setLocalRows] = useState<any>([])
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (rows) {
            setLocalRows(rows)
        }
    }, [rows])

    const [activeSort, setActiveSort] = useState<SortItem>(defaultSort ? {[defaultSort]: 1} : {})
    const [activeFilters, setActiveFilters] = useState<FilterItem>({})

    function sortFn(a, b) {
        let res = Object.keys(activeSort).reduce((result, curr, index, original) => {
            let currentResult
            if (typeof a[curr] === 'boolean') {
                currentResult = (a[curr] === b[curr] ? 0 : a[curr] ? -1 : 1) * activeSort[curr]
            } else {
                currentResult = a[curr].localeCompare(b[curr]) * activeSort[curr]
            }

            return result || currentResult
        }, 0)
        return res
    }

    function filterFn(a) {
        let res = Object.keys(activeFilters).reduce((result, curr, index, original) => {
            return result && a[curr].toLowerCase().indexOf(activeFilters[curr].toLowerCase()) > -1
        }, true)
        return res
    }

    function onSelectAll() {
        setCheckAll(true)
        const copy = JSON.parse(JSON.stringify(localRows))
        copy.filter(filterFn).map((row) => {
            row.selected = true
            return row
        })
        setLocalRows(copy)
        dispatch({type: dispatchType, value: copy})
    }

    function onDeselectAll() {
        setCheckAll(false)
        const copy = JSON.parse(JSON.stringify(localRows))
        copy.map((row) => {
            row.selected = false
            return row
        })
        setLocalRows(copy)
        dispatch({type: dispatchType, value: copy})
    }

    function onSelect(row: any) {
        const copy = JSON.parse(JSON.stringify(localRows))
        copy.find((item) => item.id === row.id).selected = !localRows.find((item) => item.id === row.id).selected
        setLocalRows(copy)
        dispatch({type: dispatchType, value: copy})
    }

    function onClickSort(id: string) {
        if (activeSort[id]) {
            let val = activeSort[id] === 1 ? -1 : activeSort[id] === -1 ? 0 : 1
            let newSort = {}
            if (val === 0) {
                delete newSort[id]
            } else {
                newSort = {
                    [id]: val
                }
            }
            setActiveSort(newSort)
        } else {
            setActiveSort({
                [id]: 1
            })
        }
    }

    const [checkAll, setCheckAll] = useState<boolean>(false)
    return <div>
        <div className={'table-actions'} id={'table-actions'}>
            <div>
                <button className={'primary-outline'} onClick={onSelectAll}>Select All</button>
                <button className={'primary-outline'} onClick={onDeselectAll}>Unselect All</button>
                <Filters columns={columns} applyFilters={setActiveFilters}/>
                {actions}
            </div>
            {rows && <div className={'table-meta'}>Showing {localRows.filter(filterFn).length} of {rows.length}. Selected rows: {localRows.filter((row: any) => row.selected === true).length}.</div>}
        </div>
        <table className={'select-table small-margin-top'}>
            <thead id={'table-head'}>
            <tr>
                <th onClick={() => onClickSort('selected')} className={'with-icon'}>
                    <div>
                        <input type={'checkbox'} checked={checkAll === true} onClick={(e) => {
                            e.stopPropagation()
                            if (checkAll) {
                                onDeselectAll()
                            } else {
                                onSelectAll()
                            }
                        }}/>
                        <SortIcon sortValue={activeSort['selected']}/>
                    </div>
                </th>
                {columns.map(({title, id}, colIndex) => <th key={`td-${colIndex}`} className={'with-icon'} onClick={() => {
                    onClickSort(id)
                }}>
                    <div>{title}
                        <SortIcon sortValue={activeSort[id]}/>
                    </div>
                </th>)}
            </tr>
            </thead>
            <tbody id={'table-body'}>
            {localRows.filter(filterFn).length === 0 && <tr>
                <td className={'no-data'} colSpan={columns.length + 1}>No data to display</td>
            </tr>}
            {localRows.filter(filterFn).sort(sortFn).map((row, index) => (
                <tr key={`tr-${index}`} onClick={() => onSelect(row)}>
                    <td><input type={'checkbox'} checked={row.selected} onChange={() => {
                        onSelect(row)
                    }}/></td>
                    {columns.map(({id}, colIndex) => <td key={`td-${colIndex}`}>{row[id]}</td>)}
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}

export default SelectTable