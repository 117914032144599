import React, {FC, useState} from 'react';
import './_apr_tabs.scss'
interface AprTabsProps {
    defaultTab?: string;
    children: any;
    onClickTab?: (id: any) => void;
}

const getActiveTab = (defaultTab: string | undefined, tabs: any) : any => {
    if(!defaultTab || tabs.length === 1) {
        return tabs[0].id
    } else {
        return defaultTab ? tabs.find(({id}) => id===defaultTab).id : tabs[0].id
    }
}

const AprTabs: FC<AprTabsProps> = ({onClickTab, defaultTab, children}) => {
    const tabs = children.filter((c) => typeof c !== 'undefined').map((c) => {
        return {title: c.props.title, id: c.props.id}
    })
    const [active, setActive] = useState<string>(getActiveTab(defaultTab, tabs))

    function handleClickTab(id: string) : void {
        setActive(id)
        if(onClickTab)
            onClickTab(id)
    }

    return <div className={'apr-tabs'}>
        <div className={`tab-titles`}>
            {tabs && tabs.map(({title, id}) => <div className={`${active === id ? 'active' : ''}`} key={id} onClick={()=>handleClickTab(id)}>
                {title}
            </div>)}
        </div>
        {children.filter((child) => typeof child !== 'undefined' && child.props.id === active)}
    </div>
}

export default AprTabs