import * as React from 'react'
import Maintain from "./maintain";
import SelectTable from "../table/select-table";
import ChangeSupervisor from "../change-supervisor/change-supervisor";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import ChangeButton from "../change-supervisor/change-button";
import {useCallback, useEffect, useState} from "react";
import leaveApi from "../../api/leave-api";
import DepartmentModel from "../../model/department.model";
import {useParams} from "react-router";
import {DepartmentOptions} from "../../model/department-options";
import AddButton from "../add-supervisor/add-button";
import AddSupervisor from "../add-supervisor/add-supervisor";
import DeleteButton from "../delete-supervisor/delete-button";
import DeleteSupervisor from "../delete-supervisor/delete-supervisor";
import EffectiveDate from "../effective-date/effective-date";

const DepartmentEmployees = () => {
    const params = useParams() //org_units, active_tab
    const [loading, setLoading] = useState(true)
    const [loadError, setLoadError] = useState(false)
    const {appState} = useAppState()
    const dispatch = useAppDispatch()
    const employeeListColumns = [
        {title: 'First Name', id: 'first_name'},
        {title: 'Last Name', id: 'last_name'},
        {title: 'MIT ID', id: 'mit_id'},
        {title: 'Kerb ID', id: 'kerberos_id'},
        {title: 'Emp. Position Title', id: 'position_title'},
        {title: 'Pers. Subarea', id: 'pers_subarea'},
        {title: 'Department', id: 'org_unit_name'},
        {title: 'Supervisor', id: 'supervisor'},
        {title: 'Supv. Position Title', id: 'supervisor_position_title'}
    ]
    const selected = appState.departmentEmployees ? appState.departmentEmployees.filter((item: any) => item.selected === true) : []

    useEffect(() => {
        dispatch({type: 'set_active_tab', value: params.active_tab})
    }, [dispatch, params.active_tab])

    useEffect(() => {
        dispatch({type: 'search', value: 'search_department'})
    }, [dispatch, params.active_tab])

    const fetchData = useCallback(() => {
        dispatch({type: 'set_is_refreshing', value: true})
        let api, data
        if (params.active_tab === 'leave') {
            api = leaveApi.fetchDepartmentEmployeesLeave
            data = appState.inputDepartmentLeave
        } else {
            api = leaveApi.fetchDepartmentEmployeesVacation
            data = appState.inputDepartmentVacation
        }
        if (api && data) {
            api(appState.inputEffectiveDate, data.map((item: DepartmentModel) => item.value)).then((resp) => {
                if (!resp.error) {
                    const people = resp.people ? resp.people.map((item: any, index: number) => {
                        item.selected = false
                        return item
                    }) : []
                    dispatch({
                        type: "update_department_employees", value: people
                    })
                } else {

                }
                setLoading(false)
                dispatch({type: 'set_is_refreshing', value: false})
            })
        }
    }, [dispatch, params.active_tab, appState.inputDepartmentVacation, appState.inputDepartmentLeave, appState.inputEffectiveDate])

    const [localDepartments, setLocalDepartments] = useState<DepartmentOptions[] | undefined>(undefined)

    useEffect(() => {
        setLocalDepartments(params.active_tab === 'leave' ? appState.departmentsLeave : appState.departmentsVacation)

        return () => {
            dispatch({type: 'set_is_refreshing', value: false})
        }
    }, [dispatch, appState.departmentsLeave, appState.departmentsVacation, params.active_tab])

    useEffect(() => {
        //once the user's departments are loaded, check if the departments in the url are matching
        if (localDepartments && localDepartments.length > 0) {
            const deps = appState.active_tab === 'leave' ? appState.inputDepartmentLeave : appState.inputDepartmentVacation
            if (!deps || (deps && deps.length === 0)) {
                const departments = appState.active_tab === 'leave' ? appState.departmentsLeave : appState.departmentsVacation
                if (departments) {
                    const depts: DepartmentOptions[] = departments.reduce((result: DepartmentOptions[], current: DepartmentModel) => {
                        if (params.org_units.indexOf(current.value) > -1) {
                            result.push(current)
                        }
                        return result
                    }, [])
                    if (depts.length === 0) {
                        setLoadError(true)
                        setLoading(false)
                    } else {
                        setLoadError(false)
                        dispatch({type: appState.active_tab === 'leave' ? "input_department_leave" : 'input_department_vacation', value: depts})
                    }
                }
            }
            if (deps) {
                fetchData()
            }
        }
    }, [dispatch, fetchData, localDepartments, params.org_units, appState.active_tab, appState.inputDepartmentLeave, appState.inputDepartmentVacation, appState.departmentsLeave, appState.departmentsVacation])

    useEffect(() => {
        if (appState.mustRefresh) {
            fetchData()
            dispatch({type: 'set_must_refresh', value: false})
        }
    }, [fetchData, dispatch, appState.mustRefresh])

    if (!loading && loadError) {
        return <div>
            <Maintain/>
            Please select one or more departments on the previous page.
        </div>
    }

    const leaveActions = [<ChangeButton key={'dep-1'} disabled={selected.length === 0}/>]
    const vacationActions = [<ChangeButton key={'dep-1'} disabled={selected.length === 0}/>, <DeleteButton key={'dep-2'} disabled={selected.length === 0}/>, <AddButton key={'dep-3'}/>]

    return !loading ? <div>
        <Maintain/>
        <EffectiveDate />
        {appState.isChangingSupervisor && <ChangeSupervisor field={'departmentEmployees'} dispatchType={'update_department_employees'} selection={selected}/>}
        {appState.isAddingSupervisor && <AddSupervisor field={'departmentEmployees'} dispatchType={'update_department_employees'} selection={selected}/>}
        {appState.isDeletingSupervisor && <DeleteSupervisor field={'departmentEmployees'} dispatchType={'update_department_employees'} selection={selected}/>}
        {appState.isRefreshing ? <div>Loading...</div> : <SelectTable columns={employeeListColumns}
                     dispatchType={'update_department_employees'}
                     rows={appState.departmentEmployees}
                     actions={appState.active_tab === 'leave' ? leaveActions : vacationActions}/>}
    </div> : <div>Loading...</div>
}

export default DepartmentEmployees