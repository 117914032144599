import * as React from 'react'
import Popover from "react-bootstrap/cjs/Popover";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import {FC} from "react";
import './_popover.scss'

const GenericPopover: FC<any> = ({triggerContent, popoverContent}) => {
    return <OverlayTrigger rootClose={true} key={'need-assistance-popover-trigger'} trigger={'click'} placement={'bottom'} overlay={<Popover id={'need-assistance-popover'}>
        <Popover.Content>{popoverContent}</Popover.Content>
    </Popover>}>
        {triggerContent}
    </OverlayTrigger>
}

export default GenericPopover