import React, {FC} from 'react';

interface AprTabProps {
    title: string;
    id: string;
}

const AprTab: FC<AprTabProps> = ({title, children}) => {
    return <div className={'apr-tab'}>
        <div>
            {children}
        </div>
    </div>
}

export default AprTab