import * as React from 'react'
import Maintain from "./maintain";
import SelectTable from "../table/select-table";
import ChangeSupervisor from "../change-supervisor/change-supervisor";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import ChangeButton from "../change-supervisor/change-button";
import {FC, useCallback, useEffect, useState} from "react";
import leaveApi from "../../api/leave-api";
import {useParams} from "react-router";
import {Person} from "../../model/person.model";
import userApi from "../../api/user-api";
import ShowError from "../alert-list/ShowError";
import EffectiveDate from "../effective-date/effective-date";

const SupervisorEmployees: FC<any> = () => {
    const params = useParams()
    const {appState} = useAppState()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const employeeListColumns = [
        {title: 'First Name', id: 'first_name'},
        {title: 'Last Name', id: 'last_name'},
        {title: 'Department', id: 'org_unit_name'},
        {title: 'Kerb ID', id: 'kerberos_id'},
        {title: 'Emp. Position Title', id: 'position_title'},
        {title: 'Pers. Subarea', id: 'pers_subarea'},
        {title: 'Supervisor', id: 'supervisor'},
        {title: 'Supv. Position Title', id: 'supervisor_position_title'}
    ]
    const selected = appState.supervisorEmployees ? appState.supervisorEmployees.filter((item: any) => item.selected === true) : []

    useEffect(() => {
        dispatch({type: 'set_active_tab', value: params.active_tab})
    }, [dispatch, params.active_tab])

    useEffect(() => {
        //if supervisor employees loaded, but the supervisor name hasn't been populated yet
        if (appState.supervisorEmployees && appState.supervisorEmployees[0] && appState.supervisorEmployees[0].supervisor === '') {
            let people = appState.supervisorEmployees.map((item: any) => {
                item.supervisor = appState.inputSupervisor ? appState.inputSupervisor!.display_name : ''
                return item
            })
            dispatch({
                type: 'update_supervisor_employees', value: people
            })
        }
    }, [dispatch, appState.inputSupervisor, appState.supervisorEmployees])

    const fetchData = useCallback((kerberos_id: string) => {
        if (appState.active_tab && kerberos_id) {
            dispatch({type: 'set_is_refreshing', value: true})
            const api = appState.active_tab === 'leave' ? leaveApi.fetchLeaveSupervised : leaveApi.fetchVacationSupervised
            api(kerberos_id!, appState.inputEffectiveDate).then((resp) => {
                if (!resp.error) {
                    let people = resp.people ? resp.people.map((item: any) => {
                        item.supervisor = appState.inputSupervisor ? appState.inputSupervisor!.display_name : ''
                        item.selected = false
                        return item
                    }) : []

                    if (appState.active_tab === 'vacation') {
                        people = people.filter((item) => item.supervisor_kerberos_id === kerberos_id)
                    }

                    dispatch({
                        type: 'update_supervisor_employees', value: people
                    })
                } else {
                    setError('Could not fetch employees.')
                }

                setLoading(false)
                dispatch({type: 'set_is_refreshing', value: false})
            })
        }

        // eslint-disable-next-line
    }, [dispatch, appState.active_tab, appState.inputEffectiveDate]) //inputSupervisor is a dependency, but causing the app to reload if it's listed here.

    useEffect(() => {
        setLoading(true)
        userApi.fetchProfile(params.kerberos_id).then((userResp) => {
            if (!userResp.error) {
                const p: Person = userResp.person ? {
                    display_name: userResp.person.displayName,
                    kerberos_id: userResp.person.kerberos_id,
                    search_name: `${userResp.person.displayName} (${userResp.person.kerberos_id})`
                } : {
                    display_name: params.kerberos_id,
                    kerberos_id: params.kerberos_id,
                    search_name: params.kerberos_id
                }
                dispatch({type: 'input_supervisor', value: p})
            }
            fetchData(params.kerberos_id)
        })
        dispatch({type: 'search', value: 'search_supervisor'})
    }, [dispatch, fetchData, params.kerberos_id, appState.active_tab])

    useEffect(() => {
        if (appState.mustRefresh) {
            fetchData(params.kerberos_id)
            dispatch({type: 'set_must_refresh', value: false})
        }
        return () => {
            dispatch({type: 'set_is_refreshing', value: false})
        }
    }, [fetchData, dispatch, appState.mustRefresh, params.kerberos_id])

    return !loading && appState.inputSupervisor ? <div>
        <Maintain/>

        <EffectiveDate />
        {error.length > 0 ? <ShowError text={error}/> : <>
            {appState.isChangingSupervisor &&
            <ChangeSupervisor field={'supervisorEmployees'} dispatchType={'update_supervisor_employees'}
                              selection={selected}/>}
            {appState.isRefreshing ? <div>Loading...</div> : <SelectTable
                dispatchType={'update_supervisor_employees'}
                columns={employeeListColumns}
                rows={appState.supervisorEmployees}
                actions={[<ChangeButton key={'one'} disabled={selected.length === 0}/>]}/>}
        </>}
    </div> : <div>Loading...</div>
}

export default SupervisorEmployees