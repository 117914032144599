const validateEffectiveDate = (date: Date): boolean => {
    return hasDateValue(date)
}

const validSupervisorDate = (date: Date): boolean => {
    return hasDateValue(date) && notPast(date)
}

const hasDateValue = (date: Date): boolean => {
    return typeof date !== 'undefined' && date !== null
}
const notPast = (inDate: Date): boolean => {
    let inDateUtc = new Date(inDate.getUTCFullYear(), inDate.getUTCMonth(), inDate.getUTCDate())
    let now = new Date()
    let today = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    return inDateUtc.getTime() >= today.getTime()
}

export {
    validateEffectiveDate,
    validSupervisorDate,
    notPast,
    hasDateValue
}