import leaveApi from "../../api/leave-api";
import {Person} from "../../model/person.model";
import {DepartmentOptions} from "../../model/department-options";

export const validateCanChangeSupervisor = async (active_tab: string, kerberos_id: string, date: Date): Promise<boolean | undefined> => {
    const type = active_tab === 'leave' ? 'SICK' : 'VACA'
    const result = await leaveApi.fetchCanChangeSupervisor(type, kerberos_id, date)
    if (!result.error) {
        return result.result
    } else {
        return false
    }
}

export const validateEmployeeEligibility = async (active_tab: string, kerberos_id: string, date?: Date): Promise<boolean | undefined> => {
    if (active_tab === "leave") { //if page reloaded, active_tab defaults to leave, so must check to see if it is really a
        let result = await leaveApi.fetchNeedLeaveSupervisor(kerberos_id!, date!)
        if (!result.error) {
            return result.result === 'ALWAYS'
        } else {
            return undefined
        }
    } else {
        let result = await leaveApi.fetchInitialize(kerberos_id)
        if (!result.error) {
            return result.can_submit_vacation
        } else {
            return undefined
        }
    }
}

export const validateViewerAuthority = async (employeeDept: string, departmentList: DepartmentOptions[]): Promise<boolean> => {
    employeeDept = employeeDept.replace(" and ", " & ")
    return departmentList.find((dep) => dep.label === employeeDept) != null
}

export const retrieveProfile = (userResp): Person => {
    return {
        display_name: userResp.person.displayName,
        kerberos_id: userResp.person.kerberos_id,
        search_name: `${userResp.person.displayName} (${userResp.person.kerberos_id})`,
        org_unit_name: userResp.person.org_unit_name
    }
}