import * as React from 'react'
import {FC} from "react";
import {useAppDispatch} from "../../context/app/app-context";

const ChangeButton: FC<any> = ({disabled}) => {
    const dispatch = useAppDispatch()
    return <button disabled={disabled} onClick={()=> {
        dispatch({type: 'change_supervisor', value: true})
    }}>Change Selected</button>
}

export default ChangeButton