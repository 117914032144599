import Amplify, { Auth } from 'aws-amplify';
import { FederatedSignInOptionsCustom } from "@aws-amplify/auth/lib/types";
const config = {
    region: process.env.REACT_APP_AMP_REGION,
    userPoolId: process.env.REACT_APP_AMP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_OATH_DOMAIN,
        scope: process.env!.REACT_APP_OATH_SCOPE!.split(' '),
        redirectSignIn: process.env.REACT_APP_OATH_REDIRECT_SIGN_ON,
        redirectSignOut: process.env.REACT_APP_OATH_REDIRECT_SIGN_OUT,
        responseType: process.env.REACT_APP_OATH_RESPONSE_TYPE,
        options: {
            AdvancedSecurityDataCollectionFlag: false
        }
    }
}
Amplify.configure(config);

class AuthProvider {
    getToken = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser().then((res) => {
                resolve(res.getSignInUserSession().accessToken.jwtToken)
            }).catch((err) => {
                Auth.federatedSignIn({customProvider: process.env.REACT_APP_CUSTOM_PROVIDER} as FederatedSignInOptionsCustom).then((res1) => {
                }).catch((err1) => {
                });
            });
        });
    }
    signOut = async (): Promise<any> => {
        return Auth.signOut();
    }
}

const authProvider = new AuthProvider()
export default authProvider;