import React, {useEffect} from 'react';
import leaveApi from "./api/leave-api";

const Admin = () => {
    useEffect(() => {
        const metadataPromise = leaveApi.metadataRefresh()
        Promise.all([metadataPromise])
            .then(([profileResponse, metadataResponse]) => {
                console.log(metadataResponse)
            })
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps
    return <div>
        <header>
            <h1 className={'page-heading'}>Admin</h1>
        </header>
    </div>
}

export default Admin