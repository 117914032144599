export interface FeatureValue {
    featureValue: FeatureValueElement[];
    releaseNotes: string;
    releaseDate:  Date;
}

export interface FeatureValueElement {
    M?: M;
}

export interface M {
    active: Active;
}

export interface Active {
    BOOL: boolean;
}

export const interpretFeatures = (fv: FeatureValue) => {
    const features = {}
    fv.featureValue.forEach((fve: FeatureValueElement) => {
        if(fve.M) {
            Object.keys(fve.M).forEach((key: string) => {
                features[key] = fve.M![key].BOOL
            })
        }
    })
    return features
}