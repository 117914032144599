import BaseApi from "./base-api";
import {FeatureValue, interpretFeatures} from "./types/FeatureValue";

export interface FetchFeatureResponse {
    features?: any;
    error?: any;
}

class FeatureApi extends BaseApi {
    readonly url_features: string

    constructor() {
        super()
        this.url_features = `${this.apiHost}/${this.apiPathFeature}/versions/1.0.0?appId=leave-supervisor`
    }

    fetchFeatures = async (): Promise<FetchFeatureResponse> => {
        let response = await fetch(this.url_features, await this.requestHeaderGet())
        if (response.status === 200) {
            let featuresJson: FeatureValue = await response.json()
            const features = interpretFeatures(featuresJson)
            return {
                features: features
            }
        } else {
            return {
                error: true
            }
        }
    }
}

const featureApi = new FeatureApi()
export default featureApi
