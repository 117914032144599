export function AppReducer(state, action) {
    switch(action.type) {
        case 'set_app_loaded': {
            return updateAppState(state, 'appLoaded', action.value)
        }
        case 'update_app_error': {
            return updateAppState(state, 'appError', action.value)
        }
        case 'update_app_error_text': {
            return updateAppState(state, 'appErrorText', action.value)
        }
        case 'update_authorized': {
            return updateAppState(state, 'authorizations', action.value)
        }
        case 'search': {
            return updateAppState(state, 'activeSearch', action.value)
        }
        case 'input_supervisor': {
            return updateAppState(state, 'inputSupervisor', action.value)
        }
        case 'input_effective_date': {
            return updateAppState(state, 'inputEffectiveDate', action.value)
        }
        case 'input_employee': {
            return updateAppState(state, 'inputEmployee', action.value)
        }
        case 'input_department_vacation': {
            return updateAppState(state, 'inputDepartmentVacation', action.value)
        }
        case 'input_department_leave': {
            return updateAppState(state, 'inputDepartmentLeave', action.value)
        }
        case 'change_supervisor': {
            return updateAppState(state, 'isChangingSupervisor', action.value)
        }
        case 'changed_supervisors': {
            return updateAppState(state, 'changedSupervisors', action.value)
        }
        case 'add_supervisor': {
            return updateAppState(state, 'isAddingSupervisor', action.value)
        }
        case 'set_profile': {
            return updateAppState(state, 'profile', action.value)
        }
        case 'set_must_refresh': {
            return updateAppState(state, 'mustRefresh', action.value)
        }
        case 'supervisor_changed_success': {
            return updateAppState(state, 'supervisorChanged', action.value)
        }
        case 'supervisor_changed_warning': {
            return updateAppState(state, 'supervisorChangedWarning', action.value)
        }
        case 'supervisor_changed_warning_text': {
            return updateAppState(state, 'supervisorChangedWarningText', action.value)
        }
        case 'update_supervisor_employees': {
            return updateAppState(state, 'supervisorEmployees', action.value)
        }
        case 'update_employee_supervisors': {
            return updateAppState(state, 'employeeSupervisors', action.value)
        }
        case 'update_department_employees': {
            return updateAppState(state, 'departmentEmployees', action.value)
        }
        case 'delete_supervisor': {
            return updateAppState(state, 'isDeletingSupervisor', action.value)
        }
        case 'supervisor_delete_success': {
            return updateAppState(state, 'supervisorDeleted', action.value)
        }
        case 'supervisor_added_success': {
            return updateAppState(state, 'supervisorAdded', action.value)
        }
        case 'update_departments_leave': {
            return updateAppState(state, 'departmentsLeave', action.value)
        }
        case 'update_departments_vacation': {
            return updateAppState(state, 'departmentsVacation', action.value)
        }
        case 'set_active_tab': {
            return updateAppState(state, 'active_tab', action.value)
        }
        case 'set_is_refreshing': {
            return updateAppState(state, 'isRefreshing', action.value)
        }
        default:
            throw new Error('Unhandled App Reducer action: ' + action.type + '.')
    }
}

function updateAppState(state, field, value) {
    return {
        appState: {
            ...state.appState,
            [field]: value
        }
    }
}