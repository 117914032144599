import * as React from 'react'
import {FC, useEffect, useState} from "react";
import './_change_supervisor.scss'
import AprModal from "@mit/apr-modal/apr-modal";
import {useAppDispatch, useAppState} from "../../context/app/app-context";
import PeopleSearch from "../people-search/people-search";
import leaveApi from "../../api/leave-api";
import DatePicker from "../form/DatePicker";
import {validSupervisorDate} from "../form/validations";
import {Person} from "../../model/person.model";
import AlertList from "../alert-list/alert-list";
import {ChangedPerson} from "../../model/changed-person-interface";
import {Position} from "../../api/types/position";

const ChangeSupervisor: FC<any> = ({field, selection}) => {
    const dispatch = useAppDispatch()
    const {appState} = useAppState()
    const [selected, setSelected] = useState<Person | undefined>(undefined)
    const [hasError, setHasError] = useState<boolean>(false)
    const [isValidating, setIsValidating] = useState<boolean>(false)
    const [error, setError] = useState<string[]>([])
    const [date, setDate] = useState<Date>(appState.inputEffectiveDate)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [warnings, setWarnings] = useState<string[]>([])
    const [supvWarnings, setSupvWarnings] = useState<string[]>([])
    const [supvWarningsDisplay, setSupvWarningsDisplay] = useState<string[]>([])
    const [positions, setPositions] = useState<Position[]>([])
    const [positionId, setPositionId] = useState<string>('')

    useEffect(() => {
        setIsSaving(false)
        dispatch({type: 'supervisor_changed_success', value: false})
    }, [dispatch])

    function closeModal() {
        setIsSaving(false)
        dispatch({type: 'change_supervisor', value: false})
    }

    function validDate(date) {
        return validSupervisorDate(date)
    }

    function filterSelectionLeave(newSupervisor: Person, people: Person[]): Person[] {
        return people.filter((e: Person) => e.selected === true).map((employee: Person) => {
            selection.forEach((current: Person) => {
                if (employee.kerberos_id === current.kerberos_id) {
                    employee.supervisor = newSupervisor.display_name
                    employee.supervisor_kerberos_id = newSupervisor.kerberos_id
                    employee.supervisor_mit_id = newSupervisor.mit_id
                    employee.supervisor_email = newSupervisor.email ? newSupervisor.email : ''
                    employee.prev_supervisor_kerberos_id = appState.inputEmployee ? current.kerberos_id : current.supervisor_kerberos_id
                    employee.prev_supervisor_name = appState.inputEmployee ? current.display_name : current.supervisor
                    employee.prev_employee_kerberos_id = employee.kerberos_id ? employee.kerberos_id : current.kerberos_id
                    employee.prev_employee_name = appState.inputEmployee ? appState.inputEmployee.display_name : current.display_name
                }
            })
            return employee
        })
    }

    function filterSelectionVacation(newSupervisor: Person, people: Person[]): Person[] {
        return people.filter((e: Person) => e.selected === true).map((employee: any) => {
            selection.forEach((current: any) => {
                if (employee.kerberos_id === current.kerberos_id) {
                    employee.kerberos_id = appState.inputEmployee ? appState.inputEmployee?.kerberos_id : current.kerberos_id
                    employee.prev_supervisor_kerberos_id = appState.inputEmployee ? current.kerberos_id : current.supervisor_kerberos_id
                    employee.prev_supervisor_name = appState.inputEmployee ? current.display_name : current.supervisor
                    employee.prev_employee_kerberos_id = employee.kerberos_id ? employee.kerberos_id : current.kerberos_id
                    employee.prev_employee_name = employee.kerberos_id ? employee.display_name : current.display_name
                    employee.supervisor = newSupervisor.display_name
                    employee.supervisor_kerberos_id = newSupervisor.kerberos_id
                    employee.supervisor_mit_id = newSupervisor.mit_id
                    employee.supervisor_email = newSupervisor.email ? newSupervisor.email : ''
                }
            })
            return employee
        })
    }

    async function onSubmit() {
        if (selected && validDate(date) && ((appState.active_tab === 'leave' && positionId !== '') || (appState.active_tab !== 'leave'))) {
            setHasError(false)
            setError([])
        } else {
            setHasError(true)
            setError(['Please complete all the fields and try again.'])
            return
        }
        setIsSaving(true)
        const employees = JSON.parse(JSON.stringify(appState[field]))
        const filtered = appState.active_tab === 'leave' ? filterSelectionLeave(selected, employees) : filterSelectionVacation(selected, employees)

        let dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

        const updateResult = appState.active_tab === 'leave' ? await leaveApi.updateSupervisor(dateStr, filtered, positionId) : await leaveApi.updateVacationSupervisors(dateStr, filtered, 'MOD')
        setIsSaving(false)
        if (updateResult.error && updateResult.error.length > 0) {
            setHasError(true)
            setError(updateResult.error)
        } else {
            const changedPersons: ChangedPerson[] = []
            filtered.forEach((item: Person) => {
                const changedPerson: ChangedPerson = {
                    employeeName: item.prev_employee_name ? item.prev_employee_name : '',
                    newSupervisor: item.supervisor ? item.supervisor : '',
                    oldSupervisor: item.prev_supervisor_name ? item.prev_supervisor_name : ''
                }
                changedPersons.push(changedPerson)
            })
            dispatch({type: 'changed_supervisors', value: changedPersons})
            dispatch({type: 'change_supervisor', value: false})
            dispatch({type: 'supervisor_changed_success', value: true})
            dispatch({type: 'set_must_refresh', value: true})
        }
    }

    async function handleSelect(supervisor: Person) {
        if (supervisor !== undefined && supervisor.kerberos_id !== undefined) {
            setPositions([]) //reset, since new supervisor is selected
            setSupvWarnings([])
            const employees = JSON.parse(JSON.stringify(appState[field]))
            const filteredEmployees = filterSelectionLeave(supervisor, employees)

            const isValid = await validateSupervisor(supervisor, date, filteredEmployees) //updateSupervisorValidateOnly
            setSelected(supervisor)
            if (isValid) {
                const positionsResp = await leaveApi.fetchValidateLeaveSupervisor(supervisor.kerberos_id, date, filteredEmployees) //Z_HR_APR_VAL_SUPERVISOR
                if (positionsResp.warnings !== undefined && positionsResp.warnings.length > 0) {
                    setSupvWarnings(positionsResp.warnings)
                }
                if (positionsResp.error === undefined || positionsResp.error === '') {
                    setPositions(positionsResp.positions ?? [])
                    if (positionsResp.positions?.length === 1) {
                        setPositionId(positionsResp.positions[0].positionId)
                    }
                } else {
                    //could not retrieve positions or invalid
                    setHasError(true)
                    setError([positionsResp.error])
                }
            }
        }
    }

    async function validateSupervisor(supervisor: Person, date, employees?: Person[]): Promise<boolean> {
        if (employees === undefined) {
            employees = filterSelectionLeave(supervisor, JSON.parse(JSON.stringify(appState[field])))
        }
        if (supervisor !== undefined && supervisor.kerberos_id !== undefined && appState.active_tab === 'leave') {
            setHasError(false)
            setIsValidating(true)
            const validateResponse = await leaveApi.updateSupervisorValidateOnly(date, employees)
            setIsValidating(false)
            if ((validateResponse.error && validateResponse.error.length > 0) || (validateResponse.warning && validateResponse.warning.length > 0)) {
                if (validateResponse.warning) {
                    setWarnings(validateResponse.warning)
                }
                if (validateResponse.error) {
                    setHasError(true)
                    setError(validateResponse.error)
                }
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    useEffect(() => {
        if (positionId !== '') {
            const warn = [...supvWarnings]
            const supvWarningsDisplay = warn.filter((s: string) => (s.indexOf(positionId) > -1)).map((s: string) => {
                let res = s.replace(positionId, `<strong>${positions.find((p: Position) => p.positionId === positionId)!.title}</strong>`)
                return `${res} and will not be changed.`
            })
            setSupvWarningsDisplay(supvWarningsDisplay)
        }
    }, [positions, supvWarnings, positionId])

    return <AprModal heading={'Change Supervisor'} onClose={closeModal} onSubmit={onSubmit}
                     disableSubmit={isSaving || hasError || isValidating} disableCancel={isSaving}>
        <div className={'change-detail'}>
            <h2>Changing {selection.filter((e: Person) => e.selected === true).length} record{selection.filter((e: Person) => e.selected === true).length > 1 ? 's' : ''}:</h2>
            {selection.filter((e: Person) => e.selected === true).map((employee: any) => {
                return `${employee.display_name}`
            }).join(', ')}
        </div>
        {!isValidating && error.length > 0 && hasError && <AlertList type={'E'} text={error}/>}
        {!isValidating && warnings.length > 0 && <AlertList text={warnings} type={'W'}/>}
        {!isValidating && supvWarningsDisplay.length > 0 && <AlertList text={supvWarningsDisplay} type={'W'}/>}
        <div className={'flex grid-container'}>
            <div className={'equal-width'}>
                <label className={'input-label top'}>New Supervisor</label>
                <PeopleSearch id={'input_supervisor'} defaultPerson={selected}
                              onSelect={(person) => {
                                  handleSelect(person)
                              }}/>
            </div>
            <div className={'equal-width'}>
                <label className={'input-label top'}>Effective Date</label>
                <DatePicker name={'effective_date'} handleChange={(newDate) => {
                    setDate(newDate)
                    if (selected !== undefined) {
                        validateSupervisor(selected, newDate).then()
                    }
                }} defaultValue={date} onlyFuture={true} validateFn={validSupervisorDate}/>
                {/*{isValidating && <span className={'validating-note'}>Validating, please wait...</span>}*/}
            </div>
            <div className={'equal-width'}>
                {appState.active_tab === 'leave' && <><label className={'input-label top'}>Supervisor Position</label>
                    <select className={'form-input supervisor-position-select'}
                            onChange={(e) => {
                                setHasError(false)
                                setPositionId(e.target.value)
                            }} value={positionId}>
                        <option></option>
                        {positions.map((pos: Position) => {
                            return <option key={pos.positionId}
                                           value={pos.positionId}>{pos.title} ({pos.orgUnitName})</option>
                        })}
                    </select></>}
            </div>
        </div>
    </AprModal>
}

export default ChangeSupervisor