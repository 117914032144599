import * as React from 'react'
import {FC} from "react";
import {useAppDispatch} from "../../context/app/app-context";
import './_delete_supervisor.scss'

const DeleteButton: FC<any> = ({disabled}) => {
    const dispatch = useAppDispatch()
    return <button disabled={disabled} className={'danger'} onClick={()=>dispatch({type: 'delete_supervisor', value: true})}>Delete Selected</button>
}

export default DeleteButton