import BaseApi from "./base-api";

export interface FetchStringResponse {
    value?: string;
    error?: any;
}

export interface PersonProfileInterface {
    kerberos_id: string;
    org_unit_name: string;
    mitId: string;
    kerberosId: string;
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    phone: string;
}

export interface FetchProfileResponse {
    person?: PersonProfileInterface;
    error?: any;
}


class UserApi extends BaseApi {
    readonly url_picture: string
    readonly url_profile: string
    constructor() {
        super()
        this.url_picture = `${this.apiHost}/${this.apiPathDigitalId}/picture`
        this.url_profile = `${this.apiHost}/${this.apiPathDigitalId}/profile`
    }

    fetchProfile = async (kerbId?: string): Promise<FetchProfileResponse> => {
        let response = await fetch(`${this.url_profile}${kerbId && kerbId !== '' ? `/${kerbId}` : ``}`, await this.requestHeaderGet())
        if (response.status === 200) {
            let data = await response.json()
            return {
                person: {
                    kerberos_id: data.kerberos,
                    kerberosId: data.kerberos,
                    mitId: data.mitid,
                    org_unit_name: data.department,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    displayName: data.display_name,
                    email: data.email,
                    phone: data.phone_number
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchPicture = async (mitId?: string): Promise<FetchStringResponse> => {
        let response = await fetch(`${this.url_picture}${mitId ? `/${mitId}` : ''}`, await this.requestHeaderGet())
        if (response.status === 200) {
            let data = await response.blob()
            return {
                value: URL.createObjectURL(data)
            }
        } else {
            return {
                error: true
            }
        }
    }
}

const userApi = new UserApi()
export default userApi