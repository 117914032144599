import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import {useState} from "react";

/*
To see why this is implemented like this, look at https://github.com/Hacker0x01/react-datepicker/issues/862
 */

class Input extends React.Component {
    render() {
        return <input {...this.props} className={'form-control'}/>;
    }
}

const DatePicker: React.FunctionComponent<any> = ({name, handleChange, defaultValue, onlyFuture, validateFn}) => {
    const FORMAT = 'MM/DD/YYYY'

    const [isValid, setIsValid] = useState<boolean>(true)

    const handleDayChange = (day: Date) => {
        handleChange(day)
        setIsValid(validateFn(day))
    }

    return <>
        <DayPickerInput onDayChange={handleDayChange} component={Input}
                           formatDate={MomentLocaleUtils.formatDate}
                           parseDate={MomentLocaleUtils.parseDate}
                           format={FORMAT}
                           dayPickerProps={onlyFuture ? {
                               modifiers: {
                                   disabled: [
                                       {
                                           before: new Date()
                                       }
                                   ]
                               }
                           } : undefined}
                           value={`${MomentLocaleUtils.formatDate(defaultValue ? defaultValue : new Date(), FORMAT, 'en')}`}
                           placeholder={`${MomentLocaleUtils.formatDate(defaultValue ? defaultValue : new Date(), FORMAT, 'en')}`}/>
        {!isValid && <span className={'form-error-text'}>Invalid date</span>}
                           </>
}

export default DatePicker