import * as React from 'react'
import {FC, useEffect, useState} from "react";
import './header.scss'
import userApi from "../../api/user-api";
import {useAppState} from "../../context/app/app-context";
import GenericPopover from "../popover/generic-popover";

const Header: FC<any> = () => {
    const [imageUrl, setImageUrl] = useState('')
    const {appState} = useAppState()

    useEffect(() => {
        userApi.fetchPicture().then((url: any) => {
            setImageUrl(url.value)
        })
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return <nav className={'atlas-header'}>
        <div className={'header-content'}>
            <div>
                <div className={'app-logo'}>
                    <img src={'/atlas-logo.svg'} alt={'Atlas Logo'}/>
                    <h1>Leave Supervisor Maintenance</h1>
                </div>
            </div>
            <div className={'header-content-section'}>
                <div className={'help-container'}>
                    <GenericPopover triggerContent={<img src={'/glyphicons-basic-195-circle-empty-question.svg'} alt={'Need Assistance'}/>}
                                    popoverContent={
                                        <div>
                                            <h2>Need Assistance?</h2>
                                            <div>Sick Time: <a href={'mailto:absence-help@mit.edu'}>absence-help@mit.edu</a></div>
                                            <div>Vacation Time: <a href={'mailto:vacation-admin-help@mit.edu'}>vacation-admin-help@mit.edu</a></div>
                                        </div>}/>
                </div>
                <div className={'username'}>
                    {imageUrl && <img src={imageUrl} alt={'User'} className={'img-object-fit'}/>}
                    {appState.profile?.displayName}
                </div>
            </div>
        </div>
    </nav>
}

export default Header