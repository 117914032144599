import authProvider from "./auth-provider";

export default class BaseApi {
    readonly apiHost: string
    readonly apiPathCovid: string
    readonly apiPathDigitalId: string
    readonly apiPathFeature: string

    constructor() {
        this.apiHost = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : ''
        this.apiPathCovid = process.env.REACT_APP_API_PATH_COVID ? process.env.REACT_APP_API_PATH_COVID : ''
        this.apiPathDigitalId = process.env.REACT_APP_API_PATH_DIGITAL_ID ? process.env.REACT_APP_API_PATH_DIGITAL_ID : ''
        this.apiPathFeature = process.env.REACT_APP_API_PATH_FEATURE ? process.env.REACT_APP_API_PATH_FEATURE : ''
    }

    checkStatusCode(status: number): boolean {
        return status >= 200 && status < 300
    }

    requestHeaderGet = async (moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "GET",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                ...moreHeaders
            })
        }
        return options
    }

    requestHeaderPost = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "POST",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] !== 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    requestHeaderPatch = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "PATCH",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] !== 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    async genericResponse(response: any) {
        if (this.checkStatusCode(response.status)) {
            return {
                success: true
            }
        } else {
            return {
                error: true
            }
        }
    }
}